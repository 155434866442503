import * as React from "react";

import Stack from "@mui/material/Stack";

import History from "./Share/History";
import PlayerStats from "./Share/PlayerStats";

export default function HistoryAndStats(): React.JSX.Element {
    return (
        <Stack spacing={2}>
            <PlayerStats />
            <History fetchUrl="/api/sessions/history/mine" />
        </Stack>
    );
}
