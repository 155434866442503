import * as React from "react";

import Button, { ButtonProps } from "@mui/material/Button";

export default function BigButton<C extends React.ElementType>(
    props: ButtonProps<C, { component?: C }>,
): React.JSX.Element {
    return (
        <Button
            {...props}
            sx={(t) => ({
                width: "100%",
                height: 100,
                fontSize: t.typography.h2.fontSize,
                lineHeight: 1,
                textAlign: "center",
                ".MuiButton-iconSizeMedium": {
                    "& > *:first-of-type": {
                        fontSize: t.typography.h2.fontSize,
                    },
                },
            })}
        />
    );
}
