import * as React from "react";
import { useParams, useNavigate, useSearchParams } from "react-router";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import ImageList from "@mui/material/ImageList";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import type { CursorResult, GridClip, Sport } from "@volley/data";

import { fetchApi, verboseDateFormat } from "../../util";
import { useCurrentUser } from "../hooks/currentUser";

import ClipDateHeader from "./ClipDateHeader";
import ClipThumbnailImageListItem from "./ClipThumbnailImageListItem";
import ClipViewer from "./ClipViewer";

enum ClipTabs {
    MY_CLIPS = "my-clips",
    FAVORITES = "favorites",
}

interface Props {
    showTitle?: boolean;
}

export default function Clips({ showTitle = true }: Props): React.JSX.Element {
    const { currentUser } = useCurrentUser();
    const navigate = useNavigate();

    // Style hooks
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

    // Search params
    const [searchParams, setSearchParams] = useSearchParams();
    const filter = searchParams.get("filter");
    const sportId = searchParams.get("sportId");
    const userId = React.useRef(searchParams.get("userId"));

    // Route param hooks
    const params = useParams<{ id: string }>();
    const selectedId = params.id ? parseInt(params.id, 10) : null;

    // Fetched data hooks
    const [sports, setSports] = React.useState<Sport[]>([]);
    const [clips, setClips] = React.useState<GridClip[]>([]);
    const [cursor, setCursor] = React.useState<string>();

    // Internal state hooks
    const [errorMessage, setErrorMessage] = React.useState("");

    // Callbacks
    const onTabChange = React.useCallback(
        (_e: React.SyntheticEvent, newValue: ClipTabs) => {
            setSearchParams(
                (current) => {
                    if (newValue === ClipTabs.MY_CLIPS) {
                        current.delete("filter");
                    } else {
                        current.set("filter", newValue);
                    }
                    return current;
                },
                { replace: true },
            );
            setCursor(undefined);
        },
        [setSearchParams],
    );

    const onChangeSport = React.useCallback(
        (e: SelectChangeEvent<number>) => {
            const { value } = e.target;
            const nextSportId =
                typeof value === "number" ? value : parseInt(value, 10);
            setSearchParams(
                (current) => {
                    if (nextSportId) {
                        current.set("sportId", nextSportId.toString());
                    } else {
                        current.delete("sportId");
                    }
                    return current;
                },
                { replace: true },
            );
        },
        [setSearchParams],
    );

    const fetchClips = React.useCallback(
        async (pageCursor?: string) => {
            const query = new URLSearchParams({
                take: "50",
                thumbnailHeight: "180",
            });

            if (pageCursor) {
                query.set("cursor", pageCursor);
            }

            if (filter) {
                query.set("filter", filter);
            }

            if (sportId) {
                query.set("sportId", sportId);
            }

            if (userId.current) {
                query.set("userId", userId.current);
            }

            const url = `/api/clips/mine?${query.toString()}`;
            const result = await fetchApi<CursorResult<GridClip>>(url);

            if (pageCursor) {
                setClips((current) => [...current, ...result.result]);
            } else {
                setClips(result.result);
            }

            setCursor(result.next);
        },
        [filter, sportId],
    );

    const favorite = React.useCallback(
        async (inputId?: number) => {
            const id = inputId ?? selectedId;
            if (!id) return;
            await fetchApi(`/api/clips/mine/${id}/favorite`, "POST");
            setClips((current) => {
                const next = current;
                const clipIndex = current.findIndex((c) => c.id === id);
                const clip = current[clipIndex];
                if (clip && currentUser?.id) {
                    clip.favorite = true;
                    next[clipIndex] = clip;
                }
                return [...next];
            });
        },
        [selectedId, currentUser?.id],
    );

    const unfavorite = React.useCallback(
        async (inputId?: number) => {
            const id = inputId ?? selectedId;
            if (!id) return;
            await fetchApi(`/api/clips/mine/${id}/favorite`, "DELETE");
            setClips((current) => {
                const next = current;
                const clipIndex = current.findIndex((c) => c.id === id);
                const clip = current[clipIndex];
                if (clip) {
                    clip.favorite = false;
                    next[clipIndex] = clip;
                }
                return [...next];
            });
        },
        [selectedId],
    );

    const deleteClip = React.useCallback(async () => {
        const id = selectedId;
        if (!id) return;

        await fetchApi(`/api/clips/mine/${id}`, "DELETE");
        await fetchClips();
        navigate(-1);
    }, [selectedId, fetchClips, navigate]);

    const onNextPage = React.useCallback(async () => {
        try {
            await fetchClips(cursor);
        } catch (e: unknown) {
            setErrorMessage((e as Error).message);
        }
    }, [fetchClips, cursor]);

    // Effects
    React.useEffect(() => {
        async function getSports() {
            const result = await fetchApi<Sport[]>(
                "/api/clips/mine/sports",
                "GET",
            );
            setSports(result);
        }
        getSports().catch((e: Error) => {
            setErrorMessage(e.message);
        });
    }, []);

    React.useEffect(() => {
        fetchClips().catch((e: Error) => setErrorMessage(e.message));
    }, [fetchClips]);

    // Rows memo
    const [clipRows, previousId, nextId] = React.useMemo(() => {
        const thumbnailsByDate: [string, React.JSX.Element[]][] = [];
        let previous: number | null = null;
        let next: number | null = null;
        const numClips = clips.length;

        // Regular `for` loop to access previous and next.
        // This loop is also dependent on the thumbnails being ordered by date
        // to group them efficiently.
        let runningThumbnails = [];
        let runningDate = "";
        for (let i = 0; i < numClips; i += 1) {
            const clip = clips[i];

            if (clip.id === selectedId) {
                previous = clips[i - 1]?.id ?? null;
                next = clips[i + 1]?.id ?? null;
            }

            const dateString = verboseDateFormat(new Date(clip.timestamp));
            if (dateString !== runningDate) {
                if (runningThumbnails.length) {
                    thumbnailsByDate.push([runningDate, runningThumbnails]);
                }
                runningDate = dateString;
                runningThumbnails = [];
            }
            runningThumbnails.push(
                <ClipThumbnailImageListItem clip={clip} key={clip.id} />,
            );
        }

        thumbnailsByDate.push([runningDate, runningThumbnails]);

        const rows = thumbnailsByDate.map(([date, thumbnails]) => (
            <React.Fragment key={date}>
                <ClipDateHeader dateString={date} />
                <ImageList cols={3}>{thumbnails}</ImageList>
            </React.Fragment>
        ));

        return [rows, previous, next];
    }, [clips, selectedId]);

    return (
        <Stack spacing={4}>
            {!!showTitle && (
                <Typography
                    variant="h2"
                    color="primary.main"
                    sx={{ fontSize: "1.5rem" }}
                >
                    Video Clips
                </Typography>
            )}
            {!!errorMessage && (
                <Typography color="error.main">{errorMessage}</Typography>
            )}
            {sports.length > 1 && (
                <FormControl fullWidth>
                    <InputLabel id="sportIdLabel">Sport</InputLabel>
                    <Select
                        labelId="sportIdLabel"
                        id="sportId"
                        name="sportId"
                        value={sportId ? parseInt(sportId, 10) : ""}
                        label="Sport"
                        onChange={onChangeSport}
                    >
                        <MenuItem value="">
                            <em>All</em>
                        </MenuItem>
                        {sports.map(({ id, label }) => (
                            <MenuItem value={id} key={id}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <Paper elevation={4} sx={{ background: theme.palette.grey[100] }}>
                <Tabs
                    value={filter ?? "my-clips"}
                    onChange={onTabChange}
                    variant="fullWidth"
                >
                    <Tab
                        label="My Clips"
                        id="tab-my-clips"
                        value={ClipTabs.MY_CLIPS}
                    />
                    <Tab
                        label="Favorites"
                        id="tab-favorites"
                        value={ClipTabs.FAVORITES}
                    />
                </Tabs>
                {clipRows}
                {!!cursor && (
                    <Button onClick={onNextPage} color="primary" fullWidth>
                        More…
                    </Button>
                )}
            </Paper>
            <Dialog
                fullScreen={!isLargeScreen}
                fullWidth={isLargeScreen}
                open={selectedId !== null}
                onClose={() => navigate(-1)}
            >
                {!!selectedId && (
                    <ClipViewer
                        id={selectedId}
                        previousId={previousId}
                        nextId={nextId}
                        userId={userId.current ?? null}
                        favorite={favorite}
                        unfavorite={unfavorite}
                        deleteClip={deleteClip}
                    />
                )}
            </Dialog>
        </Stack>
    );
}
