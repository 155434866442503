import * as React from "react";
import { useSearchParams, useNavigate } from "react-router";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { fetchApi } from "../../util";

import LinkWithQRCode from "./QRCode";

export default function UserScan(): React.JSX.Element {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string>();
    const [link, setLink] = React.useState<string>();

    React.useEffect(() => {
        async function generateLink() {
            const session = searchParams.get("session");
            if (session === null) {
                navigate("/content/history-stats/mine", { replace: true });
                return "";
            }

            const workoutsMaybe = searchParams.get("workouts");
            const workoutIds = workoutsMaybe
                ? workoutsMaybe.split(",").map((w) => Number.parseInt(w, 10))
                : undefined;

            await fetchApi("/api/sessions/share", "POST", {
                sessionId: Number.parseInt(session, 10),
                workoutIds,
            });
            const { protocol, host } = window.location;
            let url = `${protocol}//${host}/content/share/accept?session=${session}`;
            if (workoutIds) {
                url = `${url}&workouts=${workoutIds.join(",")}`;
            }
            return url;
        }

        generateLink()
            .then((l) => setLink(l))
            .catch((e: unknown) => setError((e as Error).message))
            .finally(() => setLoading(false));
    }, [searchParams, navigate]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !link) {
        return (
            <Typography>
                Sorry, we&apos;re unable to generate a sharing link at this
                time.
            </Typography>
        );
    }

    return (
        <LinkWithQRCode
            link={link}
            text="Send the below link to the existing Volley user or have them scan the QR code."
        />
    );
}
