import * as React from "react";

import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import UserLookup from "./UserLookup";
import UserScan from "./UserScan";

type ShareMode = "search" | "code";

export default function ExistingUser(): React.JSX.Element {
    const [mode, setMode] = React.useState<ShareMode>("search");

    return (
        <Stack spacing={1} paddingTop="10px">
            <Stack direction="row" justifyContent="space-between">
                <Typography>User Search</Typography>
                <Switch
                    checked={mode === "code"}
                    onChange={() =>
                        mode === "code" ? setMode("search") : setMode("code")
                    }
                />
                <Typography>QR Code</Typography>
            </Stack>
            {mode === "search" && <UserLookup />}
            {mode === "code" && <UserScan />}
        </Stack>
    );
}
