import * as React from "react";

import SwapVertIcon from "@mui/icons-material/SwapVert";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
    setPhysicsModel,
    launchHeightInches2HeadHeight,
    physics2localization,
} from "@volley/physics/dist/conversions";
import { PhysicsModelName } from "@volley/physics/dist/models";

import ResizableWorkoutVisualizer from "../../common/Visualizer/ResizableWorkoutVisualizer";
import { useSelectedSport } from "../../common/context/sport";
import { usePhysicsModelContext } from "../../hooks/PhysicsModelProvider";
import { useStatus } from "../../hooks/status";
import { LiftModal, useLift } from "../../hooks/useLift";
import usePosition from "../../hooks/usePosition";

import BigButton from "./BigButton";

const defaultLocalizationHint = {
    x: "0.0",
    y: "0.0",
    yaw: "0.0",
};

export default function Lift(): React.JSX.Element {
    const [errMsg, setErrMsg] = React.useState("");
    const [hintErrMsg, setHintErrMsg] = React.useState("");
    const { status } = useStatus();
    const {
        error,
        height,
        lowerHead,
        isLifting,
        liftRange,
        setHeight,
        stop,
        tickUp,
        tickDown,
    } = useLift();

    const {
        updatePosition,
        position,
        isLocalizing,
        isVisionFaulted,
        isVisionStarting,
    } = usePosition();

    const localizeDisabled = React.useMemo(() => {
        return isLocalizing || isVisionFaulted || isVisionStarting || isLifting;
    }, [isLocalizing, isVisionFaulted, isVisionStarting, isLifting]);

    const [targetHeight, setTargetHeight] = React.useState(height.toString());
    const hasError = errMsg !== "" || error !== null;
    const hasHintError = hintErrMsg !== "" || errMsg !== "" || error !== null;
    const moveDown = React.useCallback(async () => {
        setTargetHeight("0");
        await lowerHead();
    }, [setTargetHeight, lowerHead]);

    const { selected: sport } = useSelectedSport();
    const { physicsModelName } = usePhysicsModelContext();

    const [localizationHint, setLocalizationHint] = React.useState(
        defaultLocalizationHint,
    );

    const hintPosition = React.useMemo(() => {
        setPhysicsModel(physicsModelName as PhysicsModelName, sport);
        const asLocalized = physics2localization({
            x: Number.parseFloat(localizationHint.x),
            y: Number.parseFloat(localizationHint.y),
            z: launchHeightInches2HeadHeight(height),
        });
        const convertedHint = {
            x: asLocalized.x,
            y: asLocalized.y,
            yaw: (Number.parseFloat(localizationHint.yaw) * Math.PI) / 180.0,
            heightIn: height,
        };
        return convertedHint;
    }, [physicsModelName, sport, height, localizationHint]);

    const doLocalization = React.useCallback(() => {
        const nx = Number.parseFloat(localizationHint.x);
        const ny = Number.parseFloat(localizationHint.y);
        const nYaw = Number.parseFloat(localizationHint.yaw);
        if (Number.isNaN(nx) || Number.isNaN(ny) || Number.isNaN(nYaw)) {
            setHintErrMsg("one or more of hint x, y, yaw not a valid number");
            setLocalizationHint({ x: "0.0", y: "0.0", yaw: "0.0" });
        } else {
            let hint;
            // physics Y != 0 is how we know you want to use the hint
            if (ny != 0.0) {
                hint = hintPosition;
            }
            updatePosition(hint, true);
        }
    }, [
        updatePosition,
        localizationHint,
        setLocalizationHint,
        setHintErrMsg,
        hintPosition,
    ]);

    const setPosition = React.useCallback(async () => {
        const parsed = parseFloat(targetHeight);
        if (Number.isNaN(parsed)) {
            setErrMsg("Not a valid number!");
        } else if (parsed >= liftRange.min && parsed <= liftRange.max) {
            await setHeight(parsed);
        } else {
            setErrMsg(
                `Height must be between ${liftRange.min} and ${liftRange.max} inches`,
            );
        }
    }, [setHeight, setErrMsg, liftRange, targetHeight]);

    const faultMessage =
        status === null ||
        status.fault === null ||
        status.fault.failures.length === 0
            ? ""
            : status.fault.failures[0].message;

    return (
        <Grid
            container
            spacing={2}
            sx={{ backgroundColor: (t) => t.palette.background.default, p: 2 }}
        >
            {isLifting && (
                <LiftModal
                    stop={stop}
                    targetHeight={parseFloat(targetHeight)}
                />
            )}
            {status?.fault !== null && (
                <Grid size={12}>
                    <Box>
                        <Typography align="center" variant="h6">
                            {faultMessage}
                        </Typography>
                    </Box>
                </Grid>
            )}
            <Grid size={12}>
                <Typography variant="h2" align="center">
                    Set Trainer Height
                </Typography>
            </Grid>
            <Grid size={12}>
                <Typography align="center">
                    Current Height (inches):&nbsp;
                    {height}
                </Typography>
            </Grid>
            <Grid size={6}>
                <BigButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLifting}
                    onClick={async () =>
                        setTargetHeight((await tickDown()).toString())
                    }
                >
                    -
                </BigButton>
            </Grid>
            <Grid size={6}>
                <BigButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLifting}
                    onClick={async () =>
                        setTargetHeight((await tickUp()).toString())
                    }
                >
                    +
                </BigButton>
            </Grid>
            <Grid size={12}>
                <Box
                    alignContent="center"
                    justifyContent="center"
                    display="flex"
                >
                    <TextField
                        label="New Height (inches)"
                        name="value"
                        value={targetHeight.toString()}
                        variant="outlined"
                        onChange={(evt) => {
                            setTargetHeight(evt.target.value);
                            setErrMsg("");
                        }}
                    />
                </Box>
                {hasError && <Alert severity="error">{errMsg || error}</Alert>}
            </Grid>
            <Grid size={12}>
                <BigButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SwapVertIcon />}
                    disabled={isLifting}
                    onClick={() => setPosition()}
                >
                    Move to New Height
                </BigButton>
            </Grid>
            <Grid size={12}>
                <BigButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SwapVertIcon />}
                    disabled={isLifting}
                    onClick={moveDown}
                >
                    Lower Head all the Way
                </BigButton>
            </Grid>
            <Grid size={12}>
                <Box
                    alignContent="center"
                    justifyContent="center"
                    display="flex"
                    columnGap={2}
                >
                    <TextField
                        label="Hint X Physics (m)"
                        name="locx"
                        value={localizationHint.x}
                        variant="outlined"
                        onChange={(evt) => {
                            setLocalizationHint({
                                ...localizationHint,
                                x: evt.target.value,
                            });
                            setHintErrMsg("");
                        }}
                    />
                    <TextField
                        label="Hint Y Physics (m)"
                        name="yloc"
                        value={localizationHint.y}
                        variant="outlined"
                        onChange={(evt) => {
                            setLocalizationHint({
                                ...localizationHint,
                                y: evt.target.value,
                            });
                            setHintErrMsg("");
                        }}
                    />
                    <TextField
                        label="Hint Yaw Physics (deg)"
                        name="yawloc"
                        value={localizationHint.yaw}
                        variant="outlined"
                        onChange={(evt) => {
                            setLocalizationHint({
                                ...localizationHint,
                                yaw: evt.target.value,
                            });
                            setHintErrMsg("");
                        }}
                    />
                </Box>
                {hasHintError && <Alert severity="error">{hintErrMsg}</Alert>}
            </Grid>
            <Grid size={12}>
                <BigButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={localizeDisabled}
                    onClick={() => doLocalization()}
                    sx={{
                        mb: 2,
                    }}
                >
                    Localize
                </BigButton>
                {position && (
                    <ResizableWorkoutVisualizer
                        positionProximity="Good"
                        workout={{
                            player: [],
                            shots: [],
                            trainer: {
                                heightIn: height,
                                x: position.x,
                                y: position.y,
                                yaw: position.yaw,
                            },
                        }}
                        maxHeight={600}
                    />
                )}
            </Grid>
        </Grid>
    );
}
