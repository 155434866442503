import * as React from "react";
import { Route, Routes, useSearchParams } from "react-router";

import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { AppWorkoutWithSummary } from "@volley/data";

import fetchApi, { logFetchError } from "../../../../../../util/fetchApi";
import { useSelectedSport } from "../../../../../common/context/sport";
import { SMALLE_MAX_BALLS } from "../../../Accordions/ParamsAccordion";

import BasicConnect from "./BasicConnect";
import BasicPlay from "./BasicPlay";
import BasicPosition from "./BasicPosition";
import reducer, { Form } from "./reducer";

const Basic: React.FC = () => {
    const { selected: selectedSport } = useSelectedSport();
    const [searchParams] = useSearchParams();
    const [state, dispatch] = React.useReducer(reducer, {
        loading: true,
        error: "",
        workouts: new Map(),
        pairingSkipped: true,
        selectedShots: [],
        selectedWorkout: null,
        selectedWorkoutTag: null,
        parameters: {
            playMode: "standard",
            numberOfBalls: SMALLE_MAX_BALLS,
            initialDelay: 1,
            intervalOverride: 3,
            shuffle: false,
            speedAdjustment: undefined,
        },
        level: 1,
        workoutNeedsStopped: false,
    } as Form);

    React.useEffect(() => {
        const search = new URLSearchParams({ sport: selectedSport });
        fetchApi<AppWorkoutWithSummary[]>(
            `/api/app-workouts/basic-multi-level?${search.toString()}`,
        )
            .then((results) => {
                dispatch({
                    type: "setWorkouts",
                    value: results,
                    selectedWorkoutTag: searchParams.get("tag"),
                });
            })
            .catch((e) => {
                dispatch({ type: "setError", value: (e as Error).message });
                logFetchError(e);
            });
    }, [searchParams, selectedSport]);

    if (state.loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress color="info" />
            </div>
        );
    }

    return (
        <Stack
            spacing={1}
            sx={(t) => ({
                p: 1,
                backgroundColor: t.palette.background.paper,
                alignItems: "center",
                minHeight: "calc(100vh - 110px)",
            })}
        >
            {state.error && (
                <Typography color="error">{state.error}</Typography>
            )}
            <Routes>
                <Route
                    path=""
                    element={<BasicConnect state={state} dispatch={dispatch} />}
                />
                <Route
                    path="position"
                    element={
                        <BasicPosition state={state} dispatch={dispatch} />
                    }
                />
                <Route
                    path="play"
                    element={<BasicPlay state={state} dispatch={dispatch} />}
                />
            </Routes>
        </Stack>
    );
};

export default Basic;
