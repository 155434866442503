import * as React from "react";
import { useNavigate } from "react-router";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { LeveledWorkoutConfig } from "@volley/shared/apps/curated-workout-models";

import type { Form, FormAction } from "./reducer";

interface Props {
    state: Form;
    dispatch: React.Dispatch<FormAction>;
}

const BasicPosition: React.FC<Props> = ({ state, dispatch }: Props) => {
    const navigate = useNavigate();
    return (
        <Grid container spacing={3} sx={{ width: "100%", py: 1 }}>
            <Grid size={12}>
                <Typography variant="h3" component="h1">
                    Select Trainer Position
                </Typography>
            </Grid>
            {Object.values(state.workouts).map((workout) => (
                <Grid size={6} key={workout.id}>
                    <Stack spacing={1}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                dispatch({
                                    type: "setSelectedWorkout",
                                    value: workout,
                                });
                                navigate("../play");
                            }}
                        >
                            {workout.name}
                        </Button>
                        <ul style={{ paddingLeft: 18 }}>
                            {(
                                workout.config as unknown as LeveledWorkoutConfig
                            ).levels[1].shots.map((shot) => (
                                <li key={shot.id}>
                                    <Typography variant="body2">
                                        {shot.name}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    </Stack>
                </Grid>
            ))}
        </Grid>
    );
};

export default BasicPosition;
