import * as React from "react";
import { Route, Routes } from "react-router";

import Box from "@mui/material/Box";

import Clips from "../Clips";
import ClubStarRatingTable from "../ClubSummary/ClubFeedbackTable";
import ClubSummary from "../ClubSummary/ClubSummary";
import Lift from "../Dialog/Status/Lift";
import ProximityConfig from "../Dialog/Status/ProximityConfig";
import Status from "../Dialog/Status/Status";
import HistoryAndStats from "../HistoryAndStats";
import ShareAccept from "../Share/Accept";
import ShareSelect from "../Share/ShareSelect";
import RequireAdmin from "../common/RequireAdmin";
import { PhysicsModelProvider } from "../hooks/PhysicsModelProvider";

import AppWorkouts from "./AppWorkouts";
import SelectedSport from "./SelectedSport";
import Shots from "./Shots";

// ContextProviders is a helper component to avoid excessive nesting in the App component
function ContextProviders({
    children,
}: {
    children: React.ReactNode;
}): React.JSX.Element {
    return <PhysicsModelProvider>{children}</PhysicsModelProvider>;
}

export default function Trainer(): React.JSX.Element {
    return (
        <ContextProviders>
            <Box component="div" pt={0.5}>
                <Routes>
                    {/* These endpoints work in spite of the state of the trainer */}
                    <Route path="status/*" element={<Status />} />
                    <Route
                        path="lift/*"
                        element={
                            <RequireAdmin>
                                <Lift />
                            </RequireAdmin>
                        }
                    />
                    <Route
                        path="sports"
                        element={
                            <RequireAdmin>
                                <SelectedSport />
                            </RequireAdmin>
                        }
                    />
                    <Route path="clips/mine/:id?" element={<Clips />} />
                    <Route
                        path="history-stats/mine"
                        element={<HistoryAndStats />}
                    />
                    <Route path="club-summary">
                        <Route path="" element={<ClubSummary />} />
                        <Route
                            path="feedback"
                            element={<ClubStarRatingTable />}
                        />
                    </Route>
                    <Route path="share" element={<ShareSelect />} />
                    <Route path="share/accept" element={<ShareAccept />} />
                    {/* These endpoints don't work if we're faulted, require calibration, etc. */}
                    <Route
                        path="shots/*"
                        element={
                            <RequireAdmin>
                                <Shots />
                            </RequireAdmin>
                        }
                    />
                    <Route
                        path="proximity-config"
                        element={
                            <RequireAdmin>
                                <ProximityConfig />
                            </RequireAdmin>
                        }
                    />
                    <Route path="apps/workouts/*" element={<AppWorkouts />} />
                </Routes>
            </Box>
        </ContextProviders>
    );
}
