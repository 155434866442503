import * as React from "react";
import { Route, Routes } from "react-router";

import EditPlugin from "./EditPlugin";
import HistoryPlugin from "./HistoryPlugin";
import PlayPlugin from "./PlayPlugin";
import WorkoutList from "./WorkoutList";
import AimSpinPlay from "./apps/1-freeplay/AimSpinPlay";
import ServeAndVolley from "./apps/10-serve-and-volley/serveAndVolley";
import ServeChallenge from "./apps/12-serve-challenge";
import ResponsivePlayApp from "./apps/2-responsive/ResponsivePlayApp";
import LobApp from "./apps/3-lobs/LobApp2";
import PickleballGame from "./apps/8-pickleball-game/pickleBallGame";
import Basic from "./apps/9-multi-level/Basic/Basic";
import PointClick from "./apps/point-click/PointClick";

export default function AppWorkouts(): React.JSX.Element {
    return (
        <Routes>
            <Route path="plugin">
                <Route path="play/:appId/:id?/*" element={<PlayPlugin />} />
                <Route path="edit/:appId/:id?" element={<EditPlugin />} />
                <Route path="history/:appId/:id?" element={<HistoryPlugin />} />
            </Route>
            <Route
                path="search"
                element={<WorkoutList fetchUrl="/api/app-workouts/search" />}
            />
            <Route
                path="favorites"
                element={
                    <WorkoutList fetchUrl="/api/app-workouts/favorites/mine" />
                }
            />
            <Route path="user">
                <Route
                    path=""
                    element={
                        <WorkoutList
                            fetchUrl="/api/app-workouts/mine"
                            groupByType
                        />
                    }
                />
            </Route>
            <Route path="experimental">
                <Route path="basic/*" element={<Basic />} />
                <Route path="lobapp" element={<LobApp />} />
                <Route path="responsive/:id?" element={<ResponsivePlayApp />} />
                <Route path="pickleball" element={<PickleballGame />} />
                <Route path="serveandvolley" element={<ServeAndVolley />} />
                <Route path="servechallenge" element={<ServeChallenge />} />
                <Route path="point-click" element={<PointClick />} />
            </Route>
            <Route path="freeplay/:id?" element={<AimSpinPlay />} />
        </Routes>
    );
}
