import * as React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import { ContentProvider } from "@volley/data";

export interface PublisherSelectProps {
    selectedProviders: ContentProvider[];
    providers: ContentProvider[];
    updateSelected: (updated: ContentProvider[]) => void;
}

export default function PublisherSelect({
    selectedProviders,
    providers,
    updateSelected,
}: PublisherSelectProps): React.JSX.Element {
    const [open, setOpen] = React.useState(false);
    return (
        <FormControl fullWidth>
            <Select
                id="demo-multiple-chip"
                multiple
                fullWidth
                open={open}
                value={selectedProviders.map((p) => p.name)}
                onChange={(e) => {
                    const matchingProviders = providers.filter((p) =>
                        e.target.value.includes(p.name),
                    );
                    updateSelected(matchingProviders);
                    setOpen(false);
                }}
                IconComponent={KeyboardArrowDownIcon}
                input={
                    <Input
                        id="select-multiple-chip"
                        fullWidth
                        disableUnderline
                        placeholder="Publisher"
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "20px",
                            padding: "2px",
                            minHeight: "45px",
                        }}
                        onClick={() => {
                            setOpen(!open);
                        }}
                    />
                }
                renderValue={(selected) => (
                    <Stack direction="row" spacing={1}>
                        {providers
                            .filter((p) => selected.includes(p.name))
                            .map((value) => (
                                <Chip
                                    key={value.id}
                                    label={value.label}
                                    sx={{
                                        minWidth: "80px",
                                    }}
                                    onDelete={() => {
                                        const updated =
                                            selectedProviders.filter(
                                                (p) => p.name !== value.name,
                                            );
                                        updateSelected(updated);
                                    }}
                                />
                            ))}
                    </Stack>
                )}
            >
                {providers.map((p) => (
                    <MenuItem key={p.id} value={p.name}>
                        {p.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
