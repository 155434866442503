import * as React from "react";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { useSelectedSport } from "../common/context/sport";

import TrainerCourtPosition from "./Position/TrainerCourtPosition";

export default function SelectedSport(): React.JSX.Element {
    const { friendlyName, limits, manualPositions, selected } =
        useSelectedSport();

    const [show, setShow] = React.useState(false);
    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => setShow(e.target.checked),
        [],
    );

    return (
        <Stack
            spacing={3}
            sx={{ backgroundColor: (t) => t.palette.background.default, p: 2 }}
        >
            <Box>
                <Typography variant="h3">
                    {`Current Sport: ${friendlyName}`}
                </Typography>
            </Box>
            <TableContainer>
                <Typography variant="h4">Limits:</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Property</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Max Launch Speed (mph)</TableCell>
                            <TableCell align="right">
                                {limits.speed.maxLaunchSpeed}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Min Launch Speed (mph)</TableCell>
                            <TableCell align="right">
                                {limits.speed.minLaunchSpeed}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Launch Speed Variation (mph)</TableCell>
                            <TableCell align="right">
                                {limits.speed.variance}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Slight Spin RPM</TableCell>
                            <TableCell align="right">
                                {limits.spin.slight}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Moderate Spin RPM</TableCell>
                            <TableCell align="right">
                                {limits.spin.moderate}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Fast Spin RPM</TableCell>
                            <TableCell align="right">
                                {limits.spin.fast}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box>
                <Typography variant="h4" align="center">
                    Court Graphic
                </Typography>
                <Typography variant="h5" align="center">
                    Pre-configured Positions
                </Typography>
                <Box textAlign="center">
                    <FormControlLabel
                        control={
                            <Switch checked={show} onChange={handleChange} />
                        }
                        label="Show Touch Areas"
                    />
                </Box>
                <TrainerCourtPosition
                    debug={show}
                    mode="manual"
                    points={manualPositions}
                    scale={selected === "PICKLEBALL" ? 0.6 : 0.8}
                    size="FULL"
                    interactive={false}
                />
            </Box>
        </Stack>
    );
}
