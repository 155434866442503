import * as React from "react";

import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface ExpandableListProps {
    maxVisible: number;
    prefix: string;
    values: string[];
}

export default function ExpandableList({
    maxVisible,
    prefix,
    values,
}: ExpandableListProps): React.JSX.Element {
    const [showDialog, setShowDialog] = React.useState(false);

    const showSeeMore = values.length > maxVisible;

    let valuesToShow = [...values];
    if (showSeeMore) {
        valuesToShow = values.slice(0, maxVisible);
    }

    let textToShow = `${valuesToShow.join(",")}`;
    if (showSeeMore) {
        if (maxVisible) {
            textToShow = `${textToShow} and ${values.length - maxVisible} more.`;
        } else {
            textToShow = values.length.toString();
        }
    }

    return (
        <Stack direction="row" justifyContent="space-between">
            <Stack direction="row">
                <Typography variant="body1">{`${prefix}: ${textToShow}`}</Typography>
            </Stack>
            {showSeeMore && (
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => setShowDialog(true)}
                >
                    <OpenInFullIcon sx={{ fontSize: "16px" }} />
                </IconButton>
            )}
            <Dialog
                open={showDialog}
                scroll="paper"
                fullWidth
                onClose={() => setShowDialog(false)}
            >
                <DialogTitle>{prefix}</DialogTitle>
                <List>
                    {values.map((v) => (
                        <ListItem key={v}>{v}</ListItem>
                    ))}
                </List>
                <DialogActions>
                    <Button
                        onClick={() => setShowDialog(false)}
                        variant="contained"
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}
