import * as React from "react";
import { useNavigate } from "react-router";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function SomethingWentWrong(): React.JSX.Element {
    const navigate = useNavigate();

    return (
        <Stack component="div">
            <Typography>Something went wrong.</Typography>
            <Typography>
                Please go back and try to select another option.
            </Typography>
            <Button
                variant="contained"
                color="info"
                fullWidth
                onClick={() => navigate(-1)}
            >
                Back
            </Button>
        </Stack>
    );
}
