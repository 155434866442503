import * as React from "react";

import DoneIcon from "@mui/icons-material/Done";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { degToRad, radToDeg } from "../../../../../util/positionUtil";
import VolleyV from "../../../../common/icons/VolleyV";
import { usePairingContext } from "../../../../hooks/pairingStatus";
import type { LocalizationStatus } from "../../../../hooks/usePosition";
import AccordionSlider from "../../Shared/AccordionSlider";
import Height from "../../Shared/Height";

import { PositioningState } from "./types";

interface Props {
    positioningState: PositioningState;
    setPositioningState: (positioningState: PositioningState) => void;
    height: number;
    onChangeHeight: (height: number) => void;
    localizationStatus: LocalizationStatus;
    localizationError: string | undefined;
    onLocalizeClick: () => void;
    onCancel: () => void;
    onHintPositionConfirm: () => void;
    manualYaw: number | null;
    onChangeManualYaw: (yaw: number) => void;
}

const PointClickLocalize: React.FC<Props> = ({
    positioningState,
    setPositioningState,
    height,
    onChangeHeight,
    localizationError,
    onCancel,
    onLocalizeClick,
    localizationStatus,
    onHintPositionConfirm,
    manualYaw,
    onChangeManualYaw,
}) => {
    const { promptToPair, status: pairingStatus } = usePairingContext();
    const pair = React.useCallback(() => {
        const autoCalibrate = true;
        promptToPair(autoCalibrate);
    }, [promptToPair]);

    if (pairingStatus !== "paired") {
        return (
            <>
                <Typography>
                    Please connect to a trainer before positioning.
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={pair}
                    sx={{ color: (t) => t.palette.primary.dark }}
                    startIcon={<VolleyV />}
                >
                    Connect
                </Button>
            </>
        );
    }

    switch (positioningState) {
        case "confirmed":
        case "coordinates":
            return (
                <>
                    <Typography>
                        Tap where you’ve placed the trainer on the court above,
                        then click{" "}
                        <strong style={{ textTransform: "uppercase" }}>
                            Next
                        </strong>
                        .
                    </Typography>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Button
                            endIcon={<NavigateNextIcon />}
                            onClick={() => setPositioningState("rotation")}
                        >
                            Next
                        </Button>
                    </Stack>
                </>
            );
        case "rotation":
            return (
                <>
                    <Typography>
                        Use the slider to adjust where the trainer is pointed.
                    </Typography>
                    <AccordionSlider
                        min={-45}
                        max={45}
                        step={1}
                        value={Math.round(radToDeg(manualYaw ?? 0))}
                        onChange={(_, v) =>
                            onChangeManualYaw(degToRad(v as number))
                        }
                        valueLabelDisplay="auto"
                        valueLabelFormat={(v) => `${v}°`}
                        sx={{
                            display: "table",
                            ".MuiSlider-track": {
                                color: "primary.light",
                            },
                            "& .MuiSlider-valueLabel": {
                                fontSize: "32px",
                            },
                        }}
                    />
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Button
                            startIcon={<NavigateBeforeIcon />}
                            onClick={() => setPositioningState("coordinates")}
                        >
                            Previous
                        </Button>
                        <Button
                            startIcon={<NavigateNextIcon />}
                            onClick={() => setPositioningState("height")}
                        >
                            Next
                        </Button>
                    </Stack>
                </>
            );
        case "height":
            return (
                <>
                    <Typography>
                        Use the slider to adjust the trainer’s height.
                    </Typography>
                    <Height
                        selectedHeight={height}
                        onHeightChanged={onChangeHeight}
                    />
                    <Button
                        color="warning"
                        onClick={onHintPositionConfirm}
                        size="small"
                    >
                        Skip Confirmation
                    </Button>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Button
                            startIcon={<NavigateBeforeIcon />}
                            onClick={() => setPositioningState("rotation")}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onLocalizeClick}
                            startIcon={<DoneIcon />}
                        >
                            Confirm
                        </Button>
                    </Stack>
                </>
            );
        case "confirming": {
            if (localizationStatus === "error" || localizationError) {
                return (
                    <>
                        <Typography color="warning" align="center">
                            {localizationError}
                        </Typography>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Button
                                color="warning"
                                onClick={onHintPositionConfirm}
                            >
                                Skip Confirmation
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={onLocalizeClick}
                                startIcon={<GpsFixedIcon />}
                            >
                                Retry
                            </Button>
                        </Stack>
                    </>
                );
            }

            if (localizationStatus === "unavailable") {
                return (
                    <>
                        <Typography color="warning" align="center">
                            Confirmation Unavailable
                        </Typography>
                        <Button color="warning" onClick={onHintPositionConfirm}>
                            Skip Confirmation
                        </Button>
                    </>
                );
            }

            return (
                <>
                    <Stack spacing={2} alignItems="center">
                        <Typography>Confirming Trainer Position…</Typography>
                        <CircularProgress />
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Button
                            color="info"
                            onClick={onCancel}
                            disabled={localizationStatus === "cancelling"}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="warning"
                            onClick={() => {
                                onCancel();
                                onHintPositionConfirm();
                            }}
                        >
                            Skip Confirmation
                        </Button>
                    </Stack>
                </>
            );
        }
    }
};

export default PointClickLocalize;
