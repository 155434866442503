import * as React from "react";
import { Navigate } from "react-router";

import DoneIcon from "@mui/icons-material/Done";
import TuneIcon from "@mui/icons-material/Tune";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { AppWorkoutWithSummary } from "@volley/data";
import type { PlayMode } from "@volley/shared/apps/app-common-models";
import type {
    LeveledWorkoutConfig,
    CuratedWorkoutState,
    LevelNumber,
    CuratedWorkoutShot,
} from "@volley/shared/apps/curated-workout-models";
import type { JSONObject } from "@volley/shared/common-models";

import { CoordSys } from "../../../../../../util/position-types";
import ResizableWorkoutVisualizer from "../../../../../common/Visualizer/ResizableWorkoutVisualizer";
import type { AnimationConfig } from "../../../../../common/Visualizer/types";
import { multiLevelToVisualizer } from "../../../../../common/Visualizer/utils";
import { Sport, useSelectedSport } from "../../../../../common/context/sport";
import { LiftModal, useLift } from "../../../../../hooks/useLift";
import { SMALLE_MAX_BALLS } from "../../../Accordions/ParamsAccordion";
import PlayAppBar from "../../../Shared/PlayAppBar";
import PlayLevelSelector from "../../../Shared/PlayLevelSelector";
import SideSelector from "../../../Shared/SideSelector";
import SpeedAdjustment from "../../../Shared/SpeedAdjustment";
import ThrowInterval from "../../../Shared/ThrowInterval";
import useAppWorkoutPlay from "../../../useAppWorkoutPlay";
import BasicShotSelector from "../../shared/BasicShotSelector";

import type { BasicWorkoutTag, Form, FormAction } from "./reducer";

const workoutWithSelectedShots = (
    workout: AppWorkoutWithSummary | null,
    level: LevelNumber,
    selectedShots: CuratedWorkoutShot[],
    playMode: PlayMode,
): AppWorkoutWithSummary | null => {
    if (!workout) return null;
    const config = workout.config as unknown as LeveledWorkoutConfig;
    const nextConfig: LeveledWorkoutConfig = {
        ...config,
        levels: {
            ...config.levels,
            [level]: {
                ...config.levels[level],
                shots: selectedShots.map((shot) => ({
                    ...shot,
                    // Ignore shot padding for dual mode
                    intervalOverride:
                        playMode === "dual" ? undefined : shot.intervalOverride,
                })),
            },
        },
    };
    return {
        ...workout,
        config: nextConfig as unknown as JSONObject,
    };
};

const getBasicModeTag = (
    workout: AppWorkoutWithSummary,
): BasicWorkoutTag | null =>
    (workout.workoutTags.find((wt) =>
        [
            "BASIC_BACKCOURT",
            "BASIC_FRONTCOURT",
            "BASIC_FRONTCOURT_SIDE",
        ].includes(wt.tag.name),
    )?.tag.name as BasicWorkoutTag | undefined) ?? null;

interface Props {
    state: Form;
    dispatch: React.Dispatch<FormAction>;
}

const BasicPlay: React.FC<Props> = ({ state, dispatch }) => {
    const { stop: stopLift, checkForLift } = useLift();
    const { selected: selectedSport } = useSelectedSport();
    const [adjustOpen, setAdjustOpen] = React.useState(false);
    const [animatedShot, setAnimatedShot] =
        React.useState<CuratedWorkoutShot | null>(null);
    const {
        selectedWorkout,
        parameters,
        level,
        selectedShots,
        workoutNeedsStopped,
    } = state;

    const workoutForVisualizer = React.useMemo(() => {
        if (selectedWorkout) {
            return multiLevelToVisualizer(
                workoutWithSelectedShots(
                    selectedWorkout,
                    level,
                    animatedShot ? [animatedShot] : selectedShots,
                    parameters.playMode,
                )!,
                level,
                parameters.playMode,
                selectedSport,
            );
        }
        return undefined;
    }, [
        selectedWorkout,
        level,
        selectedShots,
        parameters.playMode,
        selectedSport,
        animatedShot,
    ]);

    const {
        pause,
        start,
        stop,
        pauseDisabled,
        playDisabled,
        playState,
        playInitiated,
        workoutState,
        captureVideo,
        captureDisabled,
        captureStatus,
    } = useAppWorkoutPlay({
        workout: workoutWithSelectedShots(
            selectedWorkout,
            level,
            selectedShots,
            parameters.playMode,
        ),
        parameters: parameters as unknown as JSONObject,
        level: level,
    });
    const curatedWorkoutState =
        workoutState as unknown as CuratedWorkoutState | null;
    const ballsThrown = curatedWorkoutState?.currentShotNumber ?? 0;
    const totalBalls = React.useMemo(
        () =>
            parameters.numberOfBalls === SMALLE_MAX_BALLS
                ? "All"
                : parameters.numberOfBalls,
        [parameters.numberOfBalls],
    );
    const shotSummary = playInitiated ? `${ballsThrown} of ${totalBalls}` : "";

    const playerPositionForSide = React.useMemo(() => {
        if (selectedWorkout) {
            const { playerPosition } =
                selectedWorkout.config as unknown as LeveledWorkoutConfig;
            return {
                x: playerPosition?.x ?? 0,
                y: playerPosition?.y ?? 0,
                sys: (selectedSport === "PLATFORM_TENNIS"
                    ? "court"
                    : "physics") as CoordSys,
            };
        }

        return {
            x: 0,
            y: 0,
            sys: "physics" as CoordSys,
        };
    }, [selectedWorkout, selectedSport]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        await stop();
    }, [stopLift, stop]);

    const onPlayClicked = React.useCallback(async () => {
        if (workoutNeedsStopped && playState !== "stopped") await stop();
        checkForLift();
        await start();
        dispatch({ type: "setWorkoutNeedsStopped", value: false });
    }, [checkForLift, start, stop, playState, workoutNeedsStopped, dispatch]);

    const onChangeSpeedAdjustment = React.useCallback(
        (speedAdjustment?: number) => {
            dispatch({
                type: "mergeParameters",
                value: { speedAdjustment },
            });
        },
        [dispatch],
    );

    const animationConfig = React.useMemo<AnimationConfig | undefined>(
        () => ({
            action: "play",
            playbackSpeed: 1.0,
            onProgressChange: () => {},
            onComplete: () => {
                setAnimatedShot(null);
            },
        }),
        [],
    );

    const setPlayMode = React.useCallback(
        (playMode: PlayMode) => {
            // Pickleball doesn't switch workouts when changing the side (for now)
            if (selectedSport !== "PICKLEBALL") {
                const tag = state.selectedWorkout
                    ? getBasicModeTag(state.selectedWorkout)
                    : null;
                if (tag === "BASIC_FRONTCOURT" && playMode !== "dual") {
                    dispatch({
                        type: "setSelectedWorkout",
                        value:
                            state.workouts.get("BASIC_FRONTCOURT_SIDE") ?? null,
                    });
                } else if (
                    tag === "BASIC_FRONTCOURT_SIDE" &&
                    playMode === "dual"
                ) {
                    dispatch({
                        type: "setSelectedWorkout",
                        value: state.workouts.get("BASIC_FRONTCOURT") ?? null,
                    });
                }
            }
            dispatch({
                type: "mergeParameters",
                value: { playMode },
            });
        },
        [selectedSport, dispatch, state.selectedWorkout, state.workouts],
    );

    if (!selectedWorkout) {
        return <Navigate to=".." replace />;
    }

    return (
        <Stack spacing={1} sx={{ width: "100%" }}>
            <ResizableWorkoutVisualizer
                positionProximity="Unavailable"
                workout={workoutForVisualizer}
                renderCameraControls={false}
                maxHeight={225}
                enableCameraControl={false}
                animationConfig={animatedShot ? animationConfig : undefined}
            />
            <Stack spacing={1} sx={{ height: "55vh", overflowY: "auto" }}>
                <SideSelector
                    disabled={playState === "playing"}
                    playerPosition={playerPositionForSide}
                    playMode={parameters.playMode}
                    setPlayMode={setPlayMode}
                    workoutX={selectedWorkout.positionX}
                    forceShowBoth={state.workouts.has("BASIC_FRONTCOURT")}
                />
                <Typography variant="caption" textAlign="center" width="100%">
                    {`${state.parameters.intervalOverride} sec. rate, ${state.parameters.numberOfBalls === SMALLE_MAX_BALLS ? "all" : state.parameters.numberOfBalls} balls`}
                </Typography>
                <Button
                    size="small"
                    color="info"
                    startIcon={<TuneIcon />}
                    onClick={() => setAdjustOpen(true)}
                >
                    Adjust Workout
                </Button>
                <BasicShotSelector
                    availableShots={
                        (
                            selectedWorkout.config as unknown as LeveledWorkoutConfig
                        ).levels[level].shots
                    }
                    selectedShots={selectedShots}
                    setSelectedShots={(value) =>
                        dispatch({
                            type: "setSelectedShots",
                            value,
                        })
                    }
                    setAnimatedShot={setAnimatedShot}
                    disabled={playState === "playing"}
                />
            </Stack>
            <PlayAppBar
                onPlayClicked={onPlayClicked}
                onPauseClicked={() => pause()}
                pauseDisabled={pauseDisabled}
                playDisabled={playDisabled || !selectedShots.length}
                showRecord={false}
                onRecordClicked={() => captureVideo()}
                playState={playState}
                playSummary={shotSummary}
                recordDisabled={captureDisabled}
                recordingStatus={captureStatus}
            />
            <LiftModal
                stop={handleLiftStop}
                targetHeight={
                    playInitiated ? selectedWorkout.positionHeight : undefined
                }
                message="The trainer is adjusting the head height."
            />
            <Dialog
                fullWidth
                open={adjustOpen}
                onClose={() => setAdjustOpen(false)}
            >
                <DialogTitle>Adjust Workout</DialogTitle>
                <Stack sx={{ p: 1 }} spacing={4}>
                    <ThrowInterval
                        disabled={playState === "playing"}
                        selectedThrowInterval={parameters.intervalOverride}
                        onUserThrowIntervalChanged={(intervalOverride) =>
                            dispatch({
                                type: "mergeParameters",
                                value: { intervalOverride },
                            })
                        }
                    />
                    <PlayLevelSelector
                        disabled={playState === "playing"}
                        selectedLevel={level}
                        setSelectedLevel={(value) =>
                            dispatch({
                                type: "setLevel",
                                value,
                            })
                        }
                        workout={selectedWorkout}
                        labelMode="difficulty"
                    />
                    <SpeedAdjustment
                        disabled={playState === "playing"}
                        value={parameters.speedAdjustment}
                        sport={selectedWorkout.sport.name as Sport}
                        cacheKey={selectedWorkout.id.toString()}
                        onChange={onChangeSpeedAdjustment}
                    />
                </Stack>
                <DialogActions>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<DoneIcon />}
                            onClick={() => setAdjustOpen(false)}
                        >
                            Done
                        </Button>
                    </DialogActions>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};

export default BasicPlay;
