import * as React from "react";

import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Button from "@mui/material/Button";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { AppId } from "../../apps";
import ModuleIcon from "../../apps/11-module/Icon";
import MultiShotIcon from "../../apps/4-multi-shot/Icon";
import SingleShotIcon from "../../apps/5-single-shot/Icon";
import MultiLevelIcon from "../../apps/9-multi-level/Icon";

interface AppFilter {
    appId: AppId;
    label: string;
}

const APP_TYPES: AppFilter[] = [
    {
        appId: 11,
        label: "Skill Building",
    },
    {
        appId: 9,
        label: "Multi-Level",
    },
    {
        appId: 5,
        label: "Single-Shot",
    },
    {
        appId: 4,
        label: "Multi-Shot",
    },
];

interface AppFilterProps {
    activeAppIdFilter: AppId | null;
    onConfirm: (updatedAttribute: AppId | null) => void;
    onCancel: () => void;
}

export type AppFilterDrawerProps = DrawerProps & AppFilterProps;

export default function SortDrawer({
    open,
    activeAppIdFilter,
    onCancel,
    onConfirm,
}: AppFilterDrawerProps): React.JSX.Element {
    function getIcon(appId: AppId) {
        switch (appId) {
            case 5:
                return (
                    <SingleShotIcon
                        sx={{ color: (t) => t.palette.primary.main }}
                    />
                );
            case 4:
            case 6:
                return (
                    <MultiShotIcon
                        sx={{ color: (t) => t.palette.primary.main }}
                    />
                );
            case 9:
                return (
                    <MultiLevelIcon
                        sx={{ color: (t) => t.palette.primary.main }}
                    />
                );
            case 11:
                return (
                    <ModuleIcon sx={{ color: (t) => t.palette.primary.main }} />
                );
            default:
                return null;
        }
    }

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={onCancel}
            PaperProps={{
                sx: {
                    borderRadius: "16px 16px 0 0",
                    backgroundColor: (t) => t.palette.primary.main,
                },
            }}
        >
            <Stack
                spacing={2}
                sx={{
                    padding: "10px",
                }}
            >
                <Typography
                    variant="h3"
                    color="white"
                    textAlign="center"
                    sx={{
                        textTransform: "none",
                    }}
                >
                    Select Workout Type
                </Typography>
                <Stack spacing={2} pb="30px">
                    {APP_TYPES.map((appType) => (
                        <Button
                            key={appType.appId}
                            variant="outlined"
                            fullWidth
                            sx={{ color: "white", borderColor: "white" }}
                            onClick={() => onConfirm(appType.appId)}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                >
                                    {getIcon(appType.appId)}
                                    <Typography
                                        sx={{
                                            textTransform: "none",
                                        }}
                                    >
                                        {appType.label}
                                    </Typography>
                                </Stack>
                                {activeAppIdFilter === appType.appId ? (
                                    <DoneIcon color="secondary" />
                                ) : undefined}
                            </Stack>
                        </Button>
                    ))}
                    <Button
                        variant="outlined"
                        fullWidth
                        sx={{ color: "white", borderColor: "white" }}
                        onClick={() => onConfirm(null)}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <DoneAllIcon />
                                <Typography
                                    sx={{
                                        textTransform: "none",
                                    }}
                                >
                                    Show All
                                </Typography>
                            </Stack>
                            {activeAppIdFilter === null ? (
                                <DoneIcon color="secondary" />
                            ) : undefined}
                        </Stack>
                    </Button>
                </Stack>
            </Stack>
        </Drawer>
    );
}
