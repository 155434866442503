import * as React from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import Alert from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Snackbar from "@mui/material/Snackbar";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { logFetchError, pairedFetchApi } from "../../../util/fetchApi";
import BigButton from "../../Dialog/Status/BigButton";
import { useStatus } from "../../hooks/status";
import Height from "../Height";
import { coerceSlider, clampFloatValue } from "../util";

import ShotRpmSlider from "./ShotRpmSlider";
import { ActionType, reducer, initialState } from "./reducer";

export default function Shots(): React.JSX.Element {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const [hasError, setHasError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const { topRpm, leftRpm, rightRpm, pan, tilt } = state;

    const [throwersSynced, setThrowersSynced] = React.useState(true);
    const theme = useTheme();
    const widerScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { status } = useStatus();
    const panRange = status?.trainer.pan.range ?? { min: 0, max: 0 };
    const tiltRange = status?.trainer.tilt.range ?? { min: 0, max: 0 };
    // const rpmRange = status?.trainer.wheels.top.range ?? { min: 0, max: 0 };
    const [panValue, setPanValue] = React.useState("0");
    const [tiltValue, setTiltValue] = React.useState("0");

    React.useEffect(() => setTiltValue(tilt.toFixed(1)), [tilt, setTiltValue]);
    React.useEffect(() => setPanValue(pan.toFixed(1)), [pan, setPanValue]);

    const makeShot = React.useCallback(async () => {
        const shot = {
            pan,
            tilt,
            topRpm,
            bottomLeftRpm: leftRpm,
            bottomRightRpm: rightRpm,
        };
        try {
            await pairedFetchApi(status?.clientId, "/api/throw", "POST", shot);
            setHasError(false);
        } catch (ex) {
            setHasError(true);
            logFetchError(ex, `Failed to throw ball: ${JSON.stringify(shot)} `);
            if (ex instanceof Error) {
                setErrorMessage(`Failed to throw ball: ${ex.message}`);
            } else {
                setErrorMessage("Failed to throw ball for unknown reason.");
            }
        }
    }, [status?.clientId, pan, tilt, topRpm, leftRpm, rightRpm]);

    const allThrowersMax = Math.max(topRpm, leftRpm, rightRpm);
    const allThrowersAvg = Math.round((topRpm + leftRpm + rightRpm) / 3);
    const allThrowersOff = allThrowersMax <= 0;

    const arrowStyle = {
        border: "1px solid",
        margin: ".5em",
        "& .MuiSvgIcon-root": {
            width: "1.5em",
            height: "1.5em",
        },
    };

    return (
        <Grid
            container
            direction="row"
            sx={{
                flexGrow: 1,
                backgroundColor: theme.palette.background.default,
                "& .MuiSlider-thumb": {
                    backgroundColor: theme.palette.primary.main,
                },
            }}
            justifyContent="center"
            spacing={2}
        >
            <Grid
                container
                justifyContent="space-evenly"
                alignItems="flex-start"
                spacing={2}
                size={{
                    xs: 12,
                    md: 10,
                }}
            >
                <Grid container size={12}>
                    <Grid container size={12}>
                        <Grid size={12}>
                            <Typography variant="h2" align="center">
                                Throw Testing
                            </Typography>
                        </Grid>
                        <Height />
                        <Grid size={12}>
                            <h3>Throwing Motors</h3>
                        </Grid>
                        <Grid size={1}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={throwersSynced}
                                        onChange={() => {
                                            if (!throwersSynced) {
                                                dispatch({
                                                    type: ActionType.UpdateMotorRpm,
                                                    motor: "all",
                                                    rpm: allThrowersAvg,
                                                });
                                            }
                                            setThrowersSynced(!throwersSynced);
                                        }}
                                        color="primary"
                                    />
                                }
                                label="Sync"
                                labelPlacement="start"
                            />
                        </Grid>
                        <ShotRpmSlider
                            label={throwersSynced ? "All" : "Average"}
                            rpm={
                                throwersSynced ? allThrowersMax : allThrowersAvg
                            }
                            setRpm={(rpm) =>
                                dispatch({
                                    type: ActionType.UpdateMotorRpm,
                                    motor: "all",
                                    rpm,
                                })
                            }
                            disabled={!throwersSynced}
                        />
                        <ShotRpmSlider
                            label="Top"
                            rpm={throwersSynced ? allThrowersMax : topRpm}
                            setRpm={(rpm) =>
                                dispatch({
                                    type: ActionType.UpdateMotorRpm,
                                    motor: "top",
                                    rpm,
                                })
                            }
                            disabled={throwersSynced}
                        />
                        <ShotRpmSlider
                            label="Left"
                            rpm={throwersSynced ? allThrowersMax : leftRpm}
                            setRpm={(rpm) =>
                                dispatch({
                                    type: ActionType.UpdateMotorRpm,
                                    motor: "left",
                                    rpm,
                                })
                            }
                            disabled={throwersSynced}
                        />
                        <ShotRpmSlider
                            label="Right"
                            rpm={throwersSynced ? allThrowersMax : rightRpm}
                            setRpm={(rpm) =>
                                dispatch({
                                    type: ActionType.UpdateMotorRpm,
                                    motor: "right",
                                    rpm,
                                })
                            }
                            disabled={throwersSynced}
                        />

                        <Grid container size={12}>
                            <Grid size={12}>
                                <h3>Aim</h3>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 3,
                                    md: 1,
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        dispatch({
                                            type: ActionType.UpdatePan,
                                            pan: clampFloatValue(
                                                pan - 0.5,
                                                panRange,
                                            ),
                                        });
                                    }}
                                    sx={arrowStyle}
                                    disabled={pan <= panRange.min}
                                    size="large"
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </Grid>
                            <Grid
                                style={{ alignSelf: "center" }}
                                size={{
                                    xs: 6,
                                    md: 10,
                                }}
                            >
                                {widerScreen ? (
                                    <Slider
                                        value={pan}
                                        onChange={(_, val) => {
                                            dispatch({
                                                type: ActionType.UpdatePan,
                                                pan: coerceSlider(val),
                                            });
                                        }}
                                        step={0.5}
                                        min={panRange.min}
                                        max={panRange.max}
                                        sx={{
                                            "&.MuiSlider-root": {
                                                padding: "35px 0",
                                            },
                                            "& .MuiSlider-thumb": {
                                                height: 36,
                                                width: 36,
                                                marginTop: -9,
                                                marginLeft: -18,
                                            },
                                            "& .MuiSlider-rail, &.MuiSlider-track":
                                                {
                                                    height: 18,
                                                },
                                        }}
                                        color="secondary"
                                    />
                                ) : (
                                    <Typography variant="h3" align="center">
                                        <TextField
                                            onChange={(evt) =>
                                                setPanValue(evt.target.value)
                                            }
                                            onBlur={(evt) =>
                                                dispatch({
                                                    type: ActionType.UpdatePan,
                                                    pan: clampFloatValue(
                                                        evt.target.value,
                                                        panRange,
                                                    ),
                                                })
                                            }
                                            onFocus={(evt) =>
                                                evt.target.select()
                                            }
                                            type="number"
                                            style={{ width: "50%" }}
                                            value={panValue}
                                        />
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                size={{
                                    xs: 3,
                                    md: 1,
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        dispatch({
                                            type: ActionType.UpdatePan,
                                            pan: clampFloatValue(
                                                pan + 0.5,
                                                panRange,
                                            ),
                                        });
                                    }}
                                    sx={arrowStyle}
                                    disabled={pan >= panRange.max}
                                    size="large"
                                >
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                            <Grid size={12}>
                                {widerScreen ? (
                                    <Typography variant="h3" align="center">
                                        {`Pan ${panValue}°`}
                                    </Typography>
                                ) : (
                                    <Typography variant="h3" align="center">
                                        Pan
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container size={12}>
                            <Grid
                                size={{
                                    xs: 3,
                                    md: 1,
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        dispatch({
                                            type: ActionType.UpdateTilt,
                                            tilt: clampFloatValue(
                                                tilt - 0.5,
                                                tiltRange,
                                            ),
                                        });
                                    }}
                                    sx={arrowStyle}
                                    disabled={tilt <= tiltRange.min}
                                    size="large"
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </Grid>
                            <Grid
                                style={{ alignSelf: "center" }}
                                size={{
                                    xs: 6,
                                    md: 10,
                                }}
                            >
                                {widerScreen ? (
                                    <Slider
                                        value={tilt}
                                        onChange={(_, val) => {
                                            dispatch({
                                                type: ActionType.UpdateTilt,
                                                tilt: coerceSlider(val),
                                            });
                                        }}
                                        min={tiltRange.min}
                                        max={tiltRange.max}
                                        sx={{
                                            "&.MuiSlider-root": {
                                                padding: "35px 0",
                                            },
                                            "& .MuiSlider-thumb": {
                                                height: 36,
                                                width: 36,
                                                marginTop: -9,
                                                marginLeft: -18,
                                            },
                                            "& .MuiSlider-rail, &.MuiSlider-track":
                                                {
                                                    height: 18,
                                                },
                                        }}
                                        color="secondary"
                                    />
                                ) : (
                                    <Typography variant="h3" align="center">
                                        <TextField
                                            onChange={(evt) =>
                                                setTiltValue(evt.target.value)
                                            }
                                            onBlur={(evt) =>
                                                dispatch({
                                                    type: ActionType.UpdateTilt,
                                                    tilt: clampFloatValue(
                                                        evt.target.value,
                                                        tiltRange,
                                                    ),
                                                })
                                            }
                                            onFocus={(evt) =>
                                                evt.target.select()
                                            }
                                            type="number"
                                            style={{ width: "50%" }}
                                            value={tiltValue}
                                        />
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                size={{
                                    xs: 3,
                                    md: 1,
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        dispatch({
                                            type: ActionType.UpdateTilt,
                                            tilt: clampFloatValue(
                                                tilt + 0.5,
                                                tiltRange,
                                            ),
                                        });
                                    }}
                                    sx={arrowStyle}
                                    disabled={pan >= tiltRange.max}
                                    size="large"
                                >
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                            <Grid size={12}>
                                {widerScreen ? (
                                    <Typography variant="h3" align="center">
                                        {`Tilt ${tiltValue}°`}
                                    </Typography>
                                ) : (
                                    <Typography variant="h3" align="center">
                                        Tilt
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid size={12}>
                    <BigButton
                        variant="contained"
                        color="primary"
                        onClick={() => makeShot()}
                        disabled={allThrowersOff}
                        endIcon={<SportsTennisIcon />}
                    >
                        Throw Now
                    </BigButton>
                </Grid>
            </Grid>
            <Snackbar
                open={hasError}
                autoHideDuration={8000}
                onClose={() => setHasError(false)}
            >
                <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
        </Grid>
    );
}
