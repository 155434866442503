import type { AppWorkoutWithSummary } from "@volley/data";
import type {
    CuratedWorkoutParameters,
    CuratedWorkoutShot,
    LeveledWorkoutConfig,
    LevelNumber,
} from "@volley/shared/apps/curated-workout-models";

export type BasicWorkoutTag =
    | "BASIC_FRONTCOURT"
    | "BASIC_FRONTCOURT_SIDE"
    | "BASIC_BACKCOURT";

export interface Form {
    loading: boolean;
    pairingSkipped: boolean;
    parameters: CuratedWorkoutParameters;
    workouts: Map<BasicWorkoutTag, AppWorkoutWithSummary>;
    selectedWorkout: AppWorkoutWithSummary | null;
    selectedWorkoutTag: BasicWorkoutTag | null;
    selectedShots: CuratedWorkoutShot[];
    workoutNeedsStopped: boolean;
    level: LevelNumber;
    error: string;
}

interface CacheState {
    selectedLevel: LevelNumber;
    selectedShotIds: string[];
}

const BASIC_STATE_KEY = "volley.basic.state";

function setCachedState(state: CacheState) {
    window.sessionStorage.setItem(BASIC_STATE_KEY, JSON.stringify(state));
}

function getCachedState(): CacheState | null {
    const state = window.sessionStorage.getItem(BASIC_STATE_KEY);
    if (!state) return null;

    try {
        const cached = JSON.parse(state);
        if (typeof cached !== "object") return null;
        return cached as CacheState;
    } catch {
        return null;
    }
}

export type FormAction =
    | { type: "setPairingSkipped"; value: boolean }
    | { type: "mergeParameters"; value: Partial<CuratedWorkoutParameters> }
    | {
          type: "setWorkouts";
          value: AppWorkoutWithSummary[];
          selectedWorkoutTag?: string | null;
      }
    | { type: "setSelectedWorkout"; value: AppWorkoutWithSummary | null }
    | { type: "setSelectedShots"; value: CuratedWorkoutShot[] }
    | { type: "setLevel"; value: LevelNumber }
    | { type: "setWorkoutNeedsStopped"; value: boolean }
    | { type: "setError"; value: string };

export default function reducer(state: Form, action: FormAction): Form {
    switch (action.type) {
        case "setPairingSkipped":
            return { ...state, pairingSkipped: action.value };
        case "mergeParameters": {
            return {
                ...state,
                parameters: { ...state.parameters, ...action.value },
                workoutNeedsStopped: true,
            };
        }
        case "setWorkouts": {
            const workouts = action.value.reduce((acc, w) => {
                const workoutTag = w.workoutTags.find((t) =>
                    [
                        "BASIC_FRONTCOURT",
                        "BASIC_FRONTCOURT_SIDE",
                        "BASIC_BACKCOURT",
                    ].includes(t.tag.name),
                );
                if (workoutTag) {
                    acc.set(workoutTag.tag.name as BasicWorkoutTag, w);
                }
                return acc;
            }, new Map<BasicWorkoutTag, AppWorkoutWithSummary>());
            const selectedWorkout = action.selectedWorkoutTag
                ? (workouts.get(action.selectedWorkoutTag as BasicWorkoutTag) ??
                  null)
                : null;

            const cachedState = getCachedState();
            let selectedShots: CuratedWorkoutShot[] = [];
            let selectedLevel = cachedState?.selectedLevel;
            if (cachedState && cachedState.selectedShotIds.length) {
                selectedShots =
                    (
                        selectedWorkout?.config as unknown as
                            | LeveledWorkoutConfig
                            | undefined
                    )?.levels[cachedState.selectedLevel].shots.filter((s) =>
                        cachedState.selectedShotIds.includes(s.id),
                    ) ?? [];
            } else {
                selectedLevel = 1;
                const firstSelectedShot = (
                    selectedWorkout?.config as unknown as
                        | LeveledWorkoutConfig
                        | undefined
                )?.levels[selectedLevel].shots.at(0);
                if (firstSelectedShot) {
                    selectedShots = [firstSelectedShot];
                }
            }

            return {
                ...state,
                level: selectedLevel ?? state.level,
                loading: false,
                workouts,
                selectedWorkout,
                selectedShots,
            };
        }
        case "setSelectedWorkout":
            setCachedState({ selectedLevel: 1, selectedShotIds: [] });
            return {
                ...state,
                selectedWorkout: action.value,
                selectedShots: [],
            };
        case "setSelectedShots":
            setCachedState({
                selectedLevel: state.level,
                selectedShotIds: action.value.map((s) => s.id),
            });
            return {
                ...state,
                selectedShots: action.value,
                workoutNeedsStopped: true,
            };
        case "setLevel":
            setCachedState({ selectedLevel: state.level, selectedShotIds: [] });
            return { ...state, level: action.value, selectedShots: [] };
        case "setWorkoutNeedsStopped":
            return { ...state, workoutNeedsStopped: action.value };
        case "setError":
            return { ...state, error: action.value, loading: false };
        default:
            throw new Error("Invalid reducer action type");
    }
}
