import * as React from "react";
import { useLocation } from "react-router";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import type { GridColDef } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid/DataGrid";

import { FeedbackResponseClubSummary } from "@volley/data";

import ClubFeedbackDialog, { UserFeedback } from "./ClubFeedbackDialog";

interface UserFeedbackRow {
    id: number;
    fullName: string;
    userFeedback: UserFeedback[];
    userId: number;
    userName: string;
}

export default function ClubFeedbackTable() {
    let rows: UserFeedbackRow[] = [];
    const location = useLocation();
    const locationState = location.state as
        | {
              locationName?: string;
              clubFeedback: FeedbackResponseClubSummary[];
          }
        | undefined;

    const clubFeedbackGrouped: Record<number, UserFeedbackRow> = {};

    locationState?.clubFeedback?.forEach((feedbackResponse) => {
        const row: UserFeedback = {
            sessionDate: feedbackResponse.sessionEndTime,
            comment: feedbackResponse.feedbackResponseComment,
            rating: feedbackResponse.feedbackResponseRating,
        };

        if (!clubFeedbackGrouped[feedbackResponse.userId]) {
            clubFeedbackGrouped[feedbackResponse.userId] = {
                id: feedbackResponse.feedbackResponseId, // used by Mui Data grid for row uniqueness
                userId: feedbackResponse.userId,
                userName: feedbackResponse.userName,
                fullName: `${feedbackResponse.firstName} ${feedbackResponse.lastName}`,
                userFeedback: [row],
            };
        } else {
            clubFeedbackGrouped[feedbackResponse.userId].userFeedback.push(row);
        }
    });

    rows = Object.values(clubFeedbackGrouped);
    rows = rows.sort((user1, user2) =>
        user1.fullName.localeCompare(user2.fullName),
    );

    const columns: GridColDef[] = [
        {
            field: "userName",
            headerName: "User",
            align: "left",
            renderCell: (params) => {
                const row = params.row as UserFeedbackRow;
                return (
                    <Tooltip title={row.fullName} enterTouchDelay={200}>
                        <span style={{ borderBottom: "1.3px dotted #000" }}>
                            {row.userName}
                        </span>
                    </Tooltip>
                );
            },
            width: 125,
        },
        {
            field: "rating",
            headerName: "Avg Rating",
            width: 115,
            align: "left",
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                const row = params.row as UserFeedbackRow;
                return (
                    <ClubFeedbackDialog
                        userId={row.userId}
                        userFeedback={row.userFeedback}
                    />
                );
            },
        },
    ];

    return (
        <Card sx={{ padding: 2 }}>
            <Stack spacing={2}>
                <Typography
                    variant="h4"
                    sx={{ textTransform: "none" }}
                    data-testid="ClubFeedbackTable_title"
                >
                    {`${locationState?.locationName || ""} Stats`}
                </Typography>
                <DataGrid
                    disableRowSelectionOnClick
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                        sorting: {
                            sortModel: [{ field: "userName", sort: "asc" }],
                        },
                    }}
                    slotProps={{
                        filterPanel: {
                            sx: {
                                ".MuiDataGrid-filterFormColumnInput": {
                                    maxWidth: "60px",
                                },
                                ".MuiDataGrid-filterFormOperatorInput": {
                                    maxWidth: "90px",
                                },
                                ".MuiDataGrid-filterFormValueInput": {
                                    maxWidth: "90px",
                                },
                            },
                        },
                        panel: {
                            sx: {
                                top: "-65px !important",
                            },
                        },
                    }}
                    sortingOrder={["asc", "desc"]}
                    sx={{
                        "&.MuiDataGrid-root": {
                            border: "none",
                        },
                    }}
                    data-testid="ClubFeedbackTable_dataGrid"
                />
            </Stack>
        </Card>
    );
}
