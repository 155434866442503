import * as React from "react";

import Tooltip from "@mui/material/Tooltip";
import type { GridColDef } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid/DataGrid";

import { UserTotalSessions } from "@volley/data";

interface UserTotalSessionRow {
    id: number;
    userName: string;
    fullName: string;
    totalSessions: number;
    totalThrows: number;
    lastSessionDate: string;
}

interface Props {
    userTotalSessions: UserTotalSessions[];
}

export function getOptionalUrlParam(
    url: string,
    locationId: number | null | undefined,
): string {
    if (locationId) {
        return `${url}?locationId=${locationId}`;
    }

    return url;
}

export default function ClubSummaryTable({ userTotalSessions }: Props) {
    const columns: GridColDef[] = [
        {
            field: "userName",
            headerName: "User",
            type: "string",
            renderCell: (params) => {
                const row = params.row as UserTotalSessionRow;
                return (
                    <Tooltip title={row.fullName} enterTouchDelay={200}>
                        <span style={{ borderBottom: "1.3px dotted #000" }}>
                            {row.userName}
                        </span>
                    </Tooltip>
                );
            },
            width: 140,
        },
        {
            field: "lastSessionDate",
            disableColumnMenu: true,
            sortable: true,
            type: "number",
            renderHeader: () => (
                <div
                    style={{
                        lineHeight: "1.25em",
                        fontWeight: 500,
                        fontSize: "0.800rem",
                    }}
                >
                    Days
                    <br />
                    Since
                    <br />
                    Last
                    <br />
                    Use
                </div>
            ),
            renderCell: (params) => {
                const row = params.row as UserTotalSessionRow;
                const lastSessionDate = new Date(row.lastSessionDate);
                let daysSinceLastSession = null;

                if (row.lastSessionDate) {
                    // control for case where session is ongoing
                    daysSinceLastSession =
                        (Date.now() - lastSessionDate.getTime()) /
                        (1000 * 3600 * 24);
                }

                return (
                    <Tooltip
                        title={
                            row.lastSessionDate
                                ? lastSessionDate.toLocaleString()
                                : ""
                        }
                        enterTouchDelay={200}
                    >
                        <span>
                            {daysSinceLastSession
                                ? Math.floor(daysSinceLastSession)
                                : "n/a"}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "totalSessions",
            disableColumnMenu: true,
            sortable: true,
            type: "number",
            renderHeader: () => (
                <div
                    style={{
                        lineHeight: "1.25em",
                        fontWeight: 500,
                        fontSize: "0.800rem",
                    }}
                >
                    <span>Total</span>
                    <br />
                    <span>Sessions</span>
                </div>
            ),
        },
    ];

    const rows = React.useMemo<UserTotalSessionRow[]>(
        () =>
            userTotalSessions.map((row) => ({
                id: row.userId,
                userName: row.userName,
                fullName: `${row.firstName} ${row.lastName}`,
                totalSessions: row.totalSessions,
                lastSessionDate: row.lastSessionTimestamp,
                totalThrows: row.totalThrows,
            })),
        [userTotalSessions],
    );

    return (
        <DataGrid
            columnHeaderHeight={80}
            disableRowSelectionOnClick
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 5,
                    },
                },
                sorting: {
                    sortModel: [{ field: "userName", sort: "asc" }],
                },
            }}
            pageSizeOptions={[5, 10, 25]}
            slotProps={{
                filterPanel: {
                    sx: {
                        ".MuiDataGrid-filterFormColumnInput": {
                            maxWidth: "60px",
                        },
                        ".MuiDataGrid-filterFormOperatorInput": {
                            maxWidth: "90px",
                        },
                        ".MuiDataGrid-filterFormValueInput": {
                            maxWidth: "90px",
                        },
                    },
                },
                panel: {
                    sx: {
                        top: "-65px !important",
                    },
                },
                footer: {
                    sx: {
                        display: "flex",
                        justifyContent: "center",
                        ".MuiInputBase-root": {
                            marginRight: 1,
                            marginLeft: 0,
                        },
                        ".MuiButtonBase-root": {
                            padding: 0.5,
                        },
                        ".MuiTablePagination-toolbar": {
                            padding: 0,
                        },
                    },
                },
            }}
            sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                    fontSize: "0.875rem",
                },
                "&.MuiDataGrid-root": {
                    border: "none",
                },
            }}
            sortingOrder={["asc", "desc"]}
            data-testid="ClubSummaryTable_dataGrid"
        />
    );
}
