import * as React from "react";
import { useLocation, useNavigate } from "react-router";

import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SortIcon from "@mui/icons-material/Sort";
import TuneIcon from "@mui/icons-material/Tune";
import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";

import {
    OffsetResult,
    AppWorkoutListItem,
    ContentProvider,
    Tag,
    TagCategoryWithRelations,
} from "@volley/data";

import logger from "../../../log";
import fetchApi, { logFetchError } from "../../../util/fetchApi";
import Loading from "../../common/Loading";
import { useSelectedSport } from "../../common/context/sport";
import { PositionWithHeight } from "../../hooks/usePosition";

import AllFilters from "./AllFilters";
import AppFilterDrawer from "./List/Filter/AppFilterDrawer";
import PublisherFilterDrawer from "./List/Filter/PublisherFilterDrawer";
import SortDrawer from "./List/Filter/SortDrawer";
import TagFilterDrawer from "./List/Filter/TagFilterDrawer";
import SubList from "./List/SubList";
import { AppId, findApp } from "./apps";
import FilterState from "./apps/shared/filterState";
import LatestPositionState from "./apps/shared/latestPosition";
import { AttributeFilter, AttributeFilterMap } from "./apps/types";

const SELECTED_WORKOUT_ID = "selectedWorkoutId";

const GROUP_BY_TYPE_CATEGORIES = [
    {
        appId: 11,
        label: "Skill Building",
    },
    {
        appId: 9,
        label: "Multi-Level",
    },
    {
        appId: 5,
        label: "Single-Shot",
    },
    {
        appId: 4,
        label: "Multi-Shot",
    },
    {
        appId: 7,
        label: "Shared With Me",
    },
];

export interface WorkoutListProps {
    fetchUrl: string;
    groupByType?: boolean;
}

function generateFetchUrl(
    fetchUrl: string,
    sport: string,
    countOnly: boolean,
    {
        selectedTags,
        selectedProviders,
        activeAttribute,
        currentPosition,
        appId,
    }: FetchParams,
): string {
    const sortField = AttributeFilterMap[activeAttribute];
    let fullUrl = `${fetchUrl}?offset=0&limit=500&sport=${sport}`;
    fullUrl += `&sortField=${sortField}&sortDirection=desc`;
    if (Object.keys(selectedTags).length) {
        const tagIds = Object.keys(selectedTags).flatMap((key) =>
            selectedTags[key as unknown as number].map((tag) => tag.id),
        );
        fullUrl += `&tagIds=${tagIds.join(",")}`;
    }
    if (selectedProviders.length) {
        const providerIds = selectedProviders.map((p) => p.id);
        fullUrl += `&providerIds=${providerIds.join(",")}`;
    }
    if (countOnly) {
        fullUrl += "&countOnly=true";
    }
    if (currentPosition) {
        fullUrl += `&trainerX=${currentPosition.x}&trainerY=${currentPosition.y}&trainerYaw=${currentPosition.yaw}`;
    }
    if (appId) {
        fullUrl += `&appId=${appId}`;
    }
    return fullUrl;
}

export interface WorkoutListState {
    urlSegment: string | null;
    loading: ValueLoading;
    drawerOpen: DrawerType;
    workouts: OffsetResult<AppWorkoutListItem> | null;
    error: string | null;
    providers: ContentProvider[];
    tagCategories: TagCategoryWithRelations[];
    selectedFilter: TagCategoryWithRelations | null;
    preFilterCount: number;
    fetchParams: FetchParams;
    fetchCountParams: FetchParams;
}

type ValueLoading = "none" | "count" | "list" | "prereqs";
type DrawerType = "none" | "appId" | "tag" | "provider" | "sort" | "all";
export interface FetchParams {
    selectedTags: Record<number, Tag[]>;
    selectedProviders: ContentProvider[];
    activeAttribute: AttributeFilter;
    currentPosition: PositionWithHeight | null;
    appId: AppId | null;
}

const defaultFetchParams: FetchParams = {
    selectedTags: {},
    selectedProviders: [],
    activeAttribute: "appId",
    currentPosition: null,
    appId: null,
};

interface WorkoutListPrereqs {
    urlSegment: string;
    tagCategories: TagCategoryWithRelations[];
    publishers: ContentProvider[];
    selectedTags: Record<number, Tag[]>;
    selectedProviders: ContentProvider[];
    selectedAppId: AppId | null;
}

type WorkoutListAction =
    | { type: "setPreReqs"; value: WorkoutListPrereqs }
    | { type: "setWorkouts"; value: OffsetResult<AppWorkoutListItem> }
    | { type: "setCount"; value: number }
    | { type: "load"; value: ValueLoading }
    | { type: "error"; value: string | null }
    | { type: "setSelectedFilter"; value: TagCategoryWithRelations | null }
    | { type: "updateCountParams"; value: FetchParams }
    | { type: "updateFetchParams"; value: FetchParams }
    | { type: "setDrawerOpen"; value: DrawerType }
    | { type: "confirmChange" }
    | { type: "cancelChange" };

const defaultWorkoutListState: WorkoutListState = {
    urlSegment: null,
    loading: "prereqs",
    drawerOpen: "none",
    workouts: null,
    error: null,
    fetchCountParams: defaultFetchParams,
    fetchParams: defaultFetchParams,
    preFilterCount: 0,
    providers: [],
    selectedFilter: null,
    tagCategories: [],
};

function cacheFilterState(urlSegment: string, params: FetchParams): void {
    FilterState.setFilter(urlSegment, {
        position: params.currentPosition !== null,
        providerIds: params.selectedProviders.map((p) => p.id),
        appId: params.appId,
        tagIds: Object.keys(params.selectedTags).flatMap((key) =>
            params.selectedTags[key as unknown as number].map((tag) => tag.id),
        ),
    });
    FilterState.setSort(urlSegment, params.activeAttribute);
}

function workoutListReducer(
    state: WorkoutListState,
    action: WorkoutListAction,
): WorkoutListState {
    switch (action.type) {
        case "setPreReqs":
            return {
                ...state,
                tagCategories: action.value.tagCategories,
                providers: action.value.publishers,
                fetchParams: {
                    ...state.fetchParams,
                    selectedTags: action.value.selectedTags,
                    selectedProviders: action.value.selectedProviders,
                    appId: action.value.selectedAppId,
                },
                fetchCountParams: {
                    ...state.fetchCountParams,
                    selectedTags: action.value.selectedTags,
                    selectedProviders: action.value.selectedProviders,
                    appId: action.value.selectedAppId,
                },
                loading: "list",
                urlSegment: action.value.urlSegment,
                workouts: null,
            };
        case "setWorkouts": {
            const updated: WorkoutListState = {
                ...state,
                workouts: action.value,
                preFilterCount: action.value.count,
            };
            return updated;
        }
        case "setCount":
            return {
                ...state,
                preFilterCount: action.value,
            };
        case "load":
            return {
                ...state,
                error: null,
                loading: action.value,
                workouts: action.value === "list" ? null : state.workouts,
            };
        case "error":
            return {
                ...state,
                error: action.value,
            };
        case "setSelectedFilter":
            return {
                ...state,
                selectedFilter: action.value,
                drawerOpen: "tag",
            };
        case "updateCountParams":
            return {
                ...state,
                loading: "count",
                fetchCountParams: action.value,
            };
        case "updateFetchParams": {
            const updated: WorkoutListState = {
                ...state,
                drawerOpen: "none",
                loading: "list",
                fetchParams: action.value,
                fetchCountParams: action.value,
            };
            if (updated.urlSegment) {
                cacheFilterState(updated.urlSegment, updated.fetchParams);
            }
            return updated;
        }
        case "cancelChange":
            return {
                ...state,
                drawerOpen: "none",
                selectedFilter: null,
                fetchCountParams: state.fetchParams,
            };
        case "confirmChange": {
            const updated: WorkoutListState = {
                ...state,
                drawerOpen: "none",
                selectedFilter: null,
                fetchParams: state.fetchCountParams,
                fetchCountParams: state.fetchCountParams,
                loading: "list",
            };
            if (updated.urlSegment) {
                cacheFilterState(updated.urlSegment, updated.fetchParams);
            }
            return updated;
        }
        case "setDrawerOpen":
            return {
                ...state,
                drawerOpen: action.value,
            };
        default:
            return state;
    }
}

export default function WorkoutList({
    fetchUrl,
    groupByType = false,
}: WorkoutListProps): React.JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const { selected: selectedSport } = useSelectedSport();

    const [state, dispatch] = React.useReducer(
        workoutListReducer,
        defaultWorkoutListState,
    );

    React.useEffect(() => {
        // if the fetch URL changes or the sport changes, we need to reload the pre-requisites
        dispatch({ type: "load", value: "prereqs" });
    }, [fetchUrl, selectedSport]);

    React.useEffect(() => {
        async function fetchTags() {
            const results = fetchApi<TagCategoryWithRelations[]>(
                `/api/tags/list?sport=${selectedSport}`,
            );
            return results;
        }
        async function fetchProviders() {
            const results = await fetchApi<OffsetResult<ContentProvider>>(
                "/api/content-providers?limit=100&offset=0",
            );
            return results.result;
        }
        if (state.loading === "prereqs") {
            Promise.allSettled([fetchTags(), fetchProviders()])
                .then(([tagResults, providerResults]) => {
                    const filteredTags: Record<number, Tag[]> = {};
                    let allTags: TagCategoryWithRelations[] = [];
                    if (tagResults.status === "fulfilled") {
                        allTags = tagResults.value;
                    }
                    let filteredProviders: ContentProvider[] = [];
                    let allProviders: ContentProvider[] = [];
                    if (providerResults.status === "fulfilled") {
                        allProviders = providerResults.value;
                    }
                    let filteredAppId: AppId | null = null;

                    const urlSegment =
                        location.pathname.split("/").pop() || "home";

                    const filter = FilterState.getFilter(urlSegment);
                    if (filter) {
                        if (filter.providerIds.length) {
                            filteredProviders = allProviders.filter((p) =>
                                filter.providerIds.includes(p.id),
                            );
                        }
                        if (filter.tagIds.length) {
                            const tags = allTags.flatMap(
                                (tagCategory) => tagCategory.tags,
                            );
                            filter.tagIds.forEach((tagId) => {
                                const match = tags.find(
                                    (tag) => tag.id === tagId,
                                );
                                if (match) {
                                    if (
                                        !Object.hasOwn(
                                            filteredTags,
                                            match.tagCategoryId,
                                        )
                                    ) {
                                        filteredTags[match.tagCategoryId] = [];
                                    }
                                    filteredTags[match.tagCategoryId].push(
                                        match,
                                    );
                                }
                            });
                        }
                        if (filter.appId) {
                            filteredAppId = filter.appId as AppId;
                        }
                    }

                    const activeSort = FilterState.getSort(urlSegment);
                    if (activeSort) {
                        dispatch({
                            type: "updateFetchParams",
                            value: {
                                ...state.fetchParams,
                                activeAttribute: activeSort as AttributeFilter,
                            },
                        });
                    }
                    dispatch({
                        type: "setPreReqs",
                        value: {
                            urlSegment,
                            tagCategories: allTags,
                            publishers: allProviders,
                            selectedProviders: filteredProviders,
                            selectedTags: filteredTags,
                            selectedAppId: filteredAppId,
                        },
                    });
                })
                .catch((e) => {
                    logFetchError(e, "Error fetching search results");
                    dispatch({
                        type: "error",
                        value: "Unable to load filters",
                    });
                });
        }
    }, [
        fetchUrl,
        selectedSport,
        state.loading,
        state.fetchParams,
        location.pathname,
    ]);

    const availableProviders = React.useMemo(() => {
        const available: number[] = [];
        if (state.workouts && state.workouts.count > 0) {
            state.workouts.result.forEach((w) => {
                if (w.provider && !available.includes(w.provider.id)) {
                    available.push(w.provider.id);
                }
            });
        }
        return state.providers.filter((p) => available.includes(p.id));
    }, [state.workouts, state.providers]);

    React.useEffect(() => {
        async function fetchData(params: FetchParams) {
            const fullUrl = generateFetchUrl(
                fetchUrl,
                selectedSport,
                false,
                params,
            );
            const results =
                await fetchApi<OffsetResult<AppWorkoutListItem>>(fullUrl);
            return results;
        }
        if (state.loading === "list" && state.fetchParams) {
            fetchData(state.fetchParams)
                .then((results) => {
                    dispatch({ type: "setWorkouts", value: results });
                })
                .catch((e) => {
                    logFetchError(e, "Error fetching search results");
                    dispatch({
                        type: "error",
                        value: "Unable to load workouts",
                    });
                })
                .finally(() => dispatch({ type: "load", value: "none" }));
        }
    }, [state.loading, state.fetchParams, fetchUrl, selectedSport]);

    React.useEffect(() => {
        async function fetchResultCount(params: FetchParams) {
            const fullUrl = generateFetchUrl(
                fetchUrl,
                selectedSport,
                true,
                params,
            );
            const results = await fetchApi<number>(fullUrl);
            return results;
        }
        if (state.loading === "count" && state.fetchCountParams) {
            fetchResultCount(state.fetchCountParams)
                .then((results) => {
                    dispatch({ type: "setCount", value: results });
                })
                .catch((e) => {
                    logFetchError(e, "Error fetching updated count");
                    dispatch({
                        type: "error",
                        value: "Unable to get updated match count",
                    });
                })
                .finally(() => dispatch({ type: "load", value: "none" }));
        }
    }, [state.loading, state.fetchCountParams, fetchUrl, selectedSport]);

    const selectedWorkoutId = sessionStorage.getItem(SELECTED_WORKOUT_ID);

    return (
        <>
            {state.error && <Alert severity="error">{state.error}</Alert>}
            <Stack
                direction="row"
                spacing={0.5}
                sx={{
                    padding: "4px",
                }}
            >
                <IconButton
                    onClick={() =>
                        dispatch({ type: "setDrawerOpen", value: "all" })
                    }
                    size="small"
                    sx={{
                        color: "white",
                    }}
                >
                    <TuneIcon />
                </IconButton>
                <Stack
                    direction="row"
                    spacing={0.5}
                    sx={{
                        overflowX: "auto",
                        whiteSpace: "nowrap",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                        maskImage:
                            "linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)",
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                >
                    {state.fetchParams.appId && (
                        <Chip
                            label={
                                GROUP_BY_TYPE_CATEGORIES.find(
                                    (c) => c.appId === state.fetchParams.appId,
                                )?.label || "Unknown"
                            }
                            onDelete={() => {
                                dispatch({
                                    type: "updateFetchParams",
                                    value: {
                                        ...state.fetchParams,
                                        appId: null,
                                    },
                                });
                            }}
                            color="info"
                            deleteIcon={<HighlightOffIcon />}
                            sx={{
                                color: "white",
                            }}
                        />
                    )}
                    {state.fetchParams.selectedProviders.map((provider) => (
                        <Chip
                            key={provider.id}
                            label={provider.label}
                            onDelete={() => {
                                const updated =
                                    state.fetchParams.selectedProviders.filter(
                                        (p) => p.id !== provider.id,
                                    );
                                dispatch({
                                    type: "updateFetchParams",
                                    value: {
                                        ...state.fetchParams,
                                        selectedProviders: updated,
                                    },
                                });
                            }}
                            color="info"
                            deleteIcon={<HighlightOffIcon />}
                            sx={{
                                color: "white",
                            }}
                        />
                    ))}
                    {Object.keys(state.fetchParams.selectedTags).map((key) =>
                        state.fetchParams.selectedTags[
                            key as unknown as number
                        ].map((tag) => (
                            <Chip
                                key={tag.id}
                                label={tag.label}
                                onDelete={() => {
                                    const updated = {
                                        ...state.fetchParams.selectedTags,
                                    };
                                    updated[key as unknown as number] = updated[
                                        key as unknown as number
                                    ].filter((t) => t.id !== tag.id);
                                    dispatch({
                                        type: "updateFetchParams",
                                        value: {
                                            ...state.fetchParams,
                                            selectedTags: updated,
                                        },
                                    });
                                }}
                                color="info"
                                sx={{
                                    color: "white",
                                }}
                            />
                        )),
                    )}
                    {LatestPositionState.getPosition() && (
                        <Chip
                            label="Current Position"
                            deleteIcon={
                                state.fetchParams.currentPosition ? (
                                    <HighlightOffIcon />
                                ) : (
                                    <CheckIcon />
                                )
                            }
                            onDelete={() => {
                                if (state.fetchParams?.currentPosition) {
                                    dispatch({
                                        type: "updateFetchParams",
                                        value: {
                                            ...(state.fetchParams || {}),
                                            currentPosition: null,
                                        },
                                    });
                                } else {
                                    dispatch({
                                        type: "updateFetchParams",
                                        value: {
                                            ...state.fetchParams,
                                            currentPosition:
                                                LatestPositionState.getPosition(),
                                        },
                                    });
                                }
                            }}
                            color={
                                state.fetchParams.currentPosition
                                    ? "info"
                                    : "primary"
                            }
                            sx={{
                                color: "white",
                            }}
                        />
                    )}
                    {state.fetchParams.appId === null && (
                        <Chip
                            color="primary"
                            icon={<ExpandMoreIcon />}
                            onClick={() =>
                                dispatch({
                                    type: "setDrawerOpen",
                                    value: "appId",
                                })
                            }
                            sx={{
                                color: "white",
                                borderRadius: "16px",
                                minWidth: "120px",
                            }}
                            label="Type"
                        />
                    )}
                    {state.tagCategories
                        .filter((tagCategory) => !tagCategory.adminOnly)
                        .map((tagCategory) => (
                            <Chip
                                color="primary"
                                icon={<ExpandMoreIcon />}
                                onClick={() =>
                                    dispatch({
                                        type: "setSelectedFilter",
                                        value: tagCategory,
                                    })
                                }
                                key={tagCategory.id}
                                sx={{
                                    color: "white",
                                }}
                                label={tagCategory.label}
                            />
                        ))}
                    <Chip
                        color="primary"
                        icon={<ExpandMoreIcon />}
                        onClick={() =>
                            dispatch({
                                type: "setDrawerOpen",
                                value: "provider",
                            })
                        }
                        sx={{
                            color: "white",
                            minWidth: "120px",
                        }}
                        label="Publisher"
                    />
                </Stack>
                <IconButton
                    onClick={() =>
                        dispatch({ type: "setDrawerOpen", value: "sort" })
                    }
                    size="small"
                    sx={{
                        color: "white",
                    }}
                >
                    <SortIcon />
                </IconButton>
            </Stack>
            <List
                sx={{
                    overflowY: "scroll",
                    overscrollBehavior: "contain",
                    maxHeight: "calc(100svh - 61px - 81px)",
                    paddingBottom: "150px",
                    paddingTop: 0,
                    marginTop: "10px",
                }}
            >
                {groupByType &&
                    GROUP_BY_TYPE_CATEGORIES.map((category) => (
                        <SubList
                            key={category.appId}
                            attributeFilter={[
                                state.fetchParams.activeAttribute,
                            ]}
                            filter={(w) => w.appId === category.appId}
                            label={category.label}
                            onClick={(id, navigateUrl) => {
                                sessionStorage.setItem(
                                    SELECTED_WORKOUT_ID,
                                    id.toString(),
                                );
                                navigate(navigateUrl);
                            }}
                            selected={
                                selectedWorkoutId
                                    ? parseInt(selectedWorkoutId, 10)
                                    : null
                            }
                            workouts={state.workouts?.result || []}
                        />
                    ))}
                {!groupByType &&
                    state.workouts?.result.map((w) => {
                        const app = findApp(w.appId);
                        if (app === null) {
                            logger.error(
                                `Unable to locate app with id ${w.appId} for workout ${w.id}`,
                            );
                            return null;
                        }
                        const selected = w.id.toString() === selectedWorkoutId;
                        return (
                            <>
                                <app.ListComponent
                                    key={w.id}
                                    selected={selected}
                                    onClick={(id, navigateUrl) => {
                                        sessionStorage.setItem(
                                            SELECTED_WORKOUT_ID,
                                            id.toString(),
                                        );
                                        navigate(navigateUrl);
                                    }}
                                    attributeFilter={[
                                        state.fetchParams.activeAttribute,
                                    ]}
                                    workout={w}
                                />
                                <Divider color="white" />
                            </>
                        );
                    })}
            </List>
            <Loading open={["prereqs", "list"].includes(state.loading)} />
            <TagFilterDrawer
                count={state.preFilterCount}
                loading={state.loading === "count"}
                selectedTags={state.fetchCountParams.selectedTags}
                selectedFilter={state.selectedFilter}
                onCancel={() => dispatch({ type: "cancelChange" })}
                onConfirmFilter={() => {
                    dispatch({
                        type: "confirmChange",
                    });
                }}
                updateCount={(updated) => {
                    dispatch({
                        type: "updateCountParams",
                        value: {
                            ...state.fetchCountParams,
                            selectedTags: updated,
                        },
                    });
                }}
            />
            <PublisherFilterDrawer
                count={state.preFilterCount}
                loading={state.loading === "count"}
                allProviders={availableProviders}
                onCancel={() => dispatch({ type: "cancelChange" })}
                onConfirmFilter={(providers) => {
                    dispatch({
                        type: "updateFetchParams",
                        value: {
                            ...state.fetchParams,
                            selectedProviders: providers,
                        },
                    });
                }}
                open={state.drawerOpen === "provider"}
                selectedProviders={state.fetchCountParams.selectedProviders}
                updateCount={(providers) => {
                    dispatch({
                        type: "updateCountParams",
                        value: {
                            ...state.fetchCountParams,
                            selectedProviders: providers,
                        },
                    });
                }}
            />
            <SortDrawer
                open={state.drawerOpen === "sort"}
                activeAttribute={state.fetchParams.activeAttribute}
                onCancel={() =>
                    dispatch({ type: "setDrawerOpen", value: "none" })
                }
                onConfirm={(updated) => {
                    localStorage.removeItem(SELECTED_WORKOUT_ID);
                    dispatch({
                        type: "updateFetchParams",
                        value: {
                            ...state.fetchParams,
                            activeAttribute: updated,
                        },
                    });
                }}
            />
            <AppFilterDrawer
                open={state.drawerOpen === "appId"}
                activeAppIdFilter={state.fetchParams.appId}
                onConfirm={(u) =>
                    dispatch({
                        type: "updateFetchParams",
                        value: { ...state.fetchParams, appId: u },
                    })
                }
                onCancel={() =>
                    dispatch({ type: "setDrawerOpen", value: "none" })
                }
            />
            <AllFilters
                appIdFilter={state.fetchCountParams.appId}
                open={state.drawerOpen === "all"}
                loading={state.loading === "count"}
                onCancel={() =>
                    dispatch({ type: "setDrawerOpen", value: "none" })
                }
                onConfirmFilter={() => dispatch({ type: "confirmChange" })}
                selectedTags={state.fetchCountParams.selectedTags}
                tagCategories={state.tagCategories}
                matchCount={state.preFilterCount}
                providers={availableProviders}
                selectedProviders={state.fetchCountParams.selectedProviders}
                showLatestPosition={LatestPositionState.getPosition() !== null}
                setAppIdFilter={(appId) =>
                    dispatch({
                        type: "updateCountParams",
                        value: { ...state.fetchParams, appId },
                    })
                }
                updatePositionCount={(usePosition) => {
                    dispatch({
                        type: "updateCountParams",
                        value: {
                            ...state.fetchCountParams,
                            currentPosition: usePosition
                                ? LatestPositionState.getPosition()
                                : null,
                        },
                    });
                }}
                updateProviderCount={(providers) => {
                    dispatch({
                        type: "updateCountParams",
                        value: {
                            ...state.fetchCountParams,
                            selectedProviders: providers,
                        },
                    });
                }}
                updateTagCount={(tags) => {
                    dispatch({
                        type: "updateCountParams",
                        value: {
                            ...state.fetchCountParams,
                            selectedTags: tags,
                        },
                    });
                }}
                useLatestPosition={
                    state.fetchParams.currentPosition !== null ||
                    state.fetchCountParams.currentPosition !== null
                }
            />
        </>
    );
}
