import * as React from "react";
import { useNavigate, useSearchParams } from "react-router";

import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import {
    FeedbackResponseClubSummary,
    PartnerLocationWithRelations,
    UserTotalSessions,
} from "@volley/data";

import { fetchApi } from "../../util";
import { logFetchError } from "../../util/fetchApi";
import HomeLocationAutocomplete from "../common/HomeLocationAutocomplete";
import { useCurrentUser } from "../hooks/currentUser";

import ClubSummaryTable, { getOptionalUrlParam } from "./ClubSummaryTable";
import ClubSummaryUsageReport from "./ClubSummaryUsageReport";

function getAvgRating(
    clubFeedback: FeedbackResponseClubSummary[],
): number | null {
    if (!clubFeedback.length) {
        return null;
    }

    const ratings = clubFeedback
        .filter((feedback) => {
            if (feedback.feedbackResponseRating) {
                return true;
            }
            return false;
        })
        .map((feedback) => feedback.feedbackResponseRating);

    if (ratings) {
        return (
            Math.round(
                (ratings.reduce((rating1, rating2) => rating1 + rating2, 0) /
                    ratings.length) *
                    100,
            ) / 100
        );
    }

    return null;
}

function statAndLabelStack(
    stat: React.JSX.Element,
    desc: string,
): React.JSX.Element {
    return (
        <Box
            component="div"
            sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
            }}
        >
            <Stack>
                {stat}
                <Typography variant="body2">{desc}</Typography>
            </Stack>
        </Box>
    );
}

/**
 * Initial page for admin is blank content ()
 * Admin will need to select a location
 */
export default function ClubSummary(): React.JSX.Element {
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedHomeLocationIdParam = parseInt(
        searchParams.get("selectedhlid") || "",
        10,
    );
    const selectedLocationIdParam = parseInt(
        searchParams.get("selectedlid") || "",
        10,
    );
    const selectedLocationNameParam = searchParams.get("selectedlname") || "";
    const { isAdmin } = useCurrentUser();
    const navigate = useNavigate();
    const [selectedHomeLocationId, setSelectedHomeLocationId] = React.useState<
        number | null
    >(selectedHomeLocationIdParam);
    const [partnerLocationId, setPartnerLocationId] = React.useState<number>();
    const [selectedLocationName, setSelectedLocationName] = React.useState<
        string | null
    >(selectedLocationNameParam);
    const [selectedLocationId, setSelectedLocationId] = React.useState<
        number | null
    >(selectedLocationIdParam);
    const [clubFeedback, setClubFeedback] = React.useState<
        FeedbackResponseClubSummary[]
    >([]);
    const [userTotalSessionRows, setUserTotalSessionRows] = React.useState<
        UserTotalSessions[]
    >([]);
    const [tabValue, setTabValue] = React.useState("1");
    const [errors, setErrors] = React.useState<string[]>([]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue.toString());
    };

    React.useEffect(() => {
        fetchApi<PartnerLocationWithRelations[]>("/api/partner-locations")
            .then((partnerLocations) => {
                if (partnerLocations.length) {
                    setSelectedLocationName(partnerLocations[0].location.name);
                    setPartnerLocationId(partnerLocations[0].locationId);
                }
            })
            .catch((error: Error) => {
                setErrors((currentErrors) => {
                    currentErrors.push(error.message);
                    return [...currentErrors];
                });
                logFetchError(
                    error,
                    "GET /api/partner-locations failed in ClubSummary component",
                );
            });
    }, []);

    React.useEffect(() => {
        // Initial page is blank for admin
        if (isAdmin() && !selectedLocationId) {
            return;
        }

        Promise.all([
            fetchApi<FeedbackResponseClubSummary[]>(
                getOptionalUrlParam("/api/feedback/club", selectedLocationId),
            ),
            fetchApi<UserTotalSessions[]>(
                getOptionalUrlParam(
                    "/api/users/club-summary",
                    selectedLocationId,
                ),
            ),
        ])
            .then(([clubFeedbackResponse, clubSummaryResponse]) => {
                setClubFeedback(clubFeedbackResponse);
                setUserTotalSessionRows(clubSummaryResponse);
            })
            .catch((error: Error) => {
                setErrors((currentErrors) => {
                    currentErrors.push(error.message);
                    return [...currentErrors];
                });
                logFetchError(
                    error,
                    "GET [/api/users/club-summary, /api/feedback/club] failed]",
                );
            });
    }, [isAdmin, selectedLocationId]);

    if (errors.length > 0) {
        return (
            <Alert
                severity="error"
                data-testid="ClubSummary_errorAlert"
                sx={{ m: 2 }}
            >
                <AlertTitle>Error</AlertTitle>
                <ul>
                    {errors.map((item) => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>
            </Alert>
        );
    }

    return (
        <Card sx={{ padding: 2 }}>
            <Stack spacing={2.5}>
                <Typography
                    variant="h4"
                    sx={{
                        textTransform: "none",
                        justifyContent: "center",
                        display: "flex",
                    }}
                    data-testid="ClubSummary_SelectedLocName"
                >
                    {`${selectedLocationName || ""} Stats`}
                </Typography>
                {isAdmin() && (
                    <HomeLocationAutocomplete
                        homeLocationId={selectedHomeLocationId}
                        onChange={(loc) => {
                            setSelectedHomeLocationId(
                                loc?.homeLocation?.id ?? null,
                            );
                            setSelectedLocationName(loc?.name ?? null);
                            setSelectedLocationId(loc?.id ?? null);
                        }}
                    />
                )}
                <TabContext value={tabValue}>
                    <Box
                        sx={{ display: "flex", justifyContent: "center" }}
                        component="div"
                    >
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="users" value="1" />
                            <Tab label="usage report" value="2" />
                        </Tabs>
                    </Box>
                    <TabPanel value="1" sx={{ m: 0, p: 0 }}>
                        <Stack
                            direction="row"
                            spacing={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                m: 0,
                                mb: 3,
                            }}
                        >
                            {statAndLabelStack(
                                <Typography
                                    variant="body1"
                                    sx={{ fontSize: "1.8em" }}
                                    data-testid="ClubSummary_activeTotalUsers"
                                >
                                    {userTotalSessionRows.length}
                                </Typography>,
                                "Total Users",
                            )}
                            {clubFeedback.length > 0
                                ? statAndLabelStack(
                                      <Typography
                                          onClick={() => {
                                              if (isAdmin()) {
                                                  const params =
                                                      new URLSearchParams();
                                                  params.set(
                                                      "selectedlid",
                                                      selectedLocationId?.toString() ||
                                                          "",
                                                  );
                                                  params.set(
                                                      "selectedhlid",
                                                      selectedHomeLocationId?.toString() ||
                                                          "",
                                                  );
                                                  params.set(
                                                      "selectedlname",
                                                      selectedLocationName ||
                                                          "",
                                                  );
                                                  setSearchParams(params, {
                                                      replace: true,
                                                  });
                                              }
                                              navigate("feedback", {
                                                  state: {
                                                      locationName:
                                                          selectedLocationName,
                                                      clubFeedback,
                                                  },
                                              });
                                          }}
                                          variant="body1"
                                          sx={{
                                              color: "rgb(9, 110, 229)",
                                              textDecoration: "underline",
                                              fontSize: "1.8em",
                                          }}
                                          data-testid="ClubSummary_avgRatingLink"
                                      >
                                          {getAvgRating(clubFeedback)}
                                          /5
                                      </Typography>,
                                      "Average Rating",
                                  )
                                : statAndLabelStack(
                                      <Typography
                                          variant="body1"
                                          sx={{ fontSize: "1.8em" }}
                                      >
                                          n/a
                                      </Typography>,
                                      "Average Rating",
                                  )}
                        </Stack>
                        <ClubSummaryTable
                            userTotalSessions={userTotalSessionRows}
                        />
                    </TabPanel>
                    <TabPanel value="2" sx={{ p: 1 }}>
                        {(isAdmin() && selectedLocationId) ||
                        (!isAdmin() && partnerLocationId) ? (
                            <ClubSummaryUsageReport
                                initialRowsPerPage={50}
                                locationId={
                                    selectedLocationId ||
                                    partnerLocationId ||
                                    undefined
                                }
                            />
                        ) : (
                            <Box
                                component="div"
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    m: 5,
                                }}
                            >
                                <Typography>
                                    Please select a location
                                </Typography>
                            </Box>
                        )}
                    </TabPanel>
                </TabContext>
            </Stack>
        </Card>
    );
}
