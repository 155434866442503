import * as React from "react";
import { Navigate } from "react-router";

import Button from "@mui/material/Button";

import PairSelect from "../../../../../Sessions/Pair/PairSelect";
import { usePairingContext } from "../../../../../hooks/pairingStatus";

import type { Form, FormAction } from "./reducer";

interface Props {
    state: Form;
    dispatch: React.Dispatch<FormAction>;
}

const BasicConnect: React.FC<Props> = ({ state, dispatch }) => {
    const { status } = usePairingContext();

    if (status === "paired" || state.pairingSkipped) {
        return <Navigate to="position" replace />;
    }

    return (
        <>
            <PairSelect alwaysAutoCalibrate />
            <Button
                onClick={() =>
                    dispatch({ type: "setPairingSkipped", value: true })
                }
                size="large"
            >
                Skip
            </Button>
        </>
    );
};

export default BasicConnect;
