import * as React from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { SessionWithDeepRelations } from "@volley/data";

import { terseTimeFormat } from "../../util";
import usePaginatedData from "../hooks/usePaginatedData";

interface Props {
    userId?: number;
    locationId?: number;
    trainerId?: number;
    initialRowsPerPage?: number;
}

function sessionFromJson(
    session: SessionWithDeepRelations,
): SessionWithDeepRelations {
    return {
        ...session,
        startTime: new Date(session.startTime),
        endTime: session.endTime ? new Date(session.endTime) : null,
        createdAt: new Date(session.createdAt),
    };
}

export default function ClubSummaryUsageReport({
    userId,
    locationId,
    trainerId,
    initialRowsPerPage,
}: Props): React.JSX.Element {
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);

    const params = React.useMemo(() => {
        const newParams: {
            userId?: string;
            locationId?: string;
            trainerId?: string;
            includeSessionUser: string;
            excludeInternalUsers: string;
        } = { includeSessionUser: "true", excludeInternalUsers: "true" };
        if (userId) newParams.userId = String(userId);
        if (locationId) newParams.locationId = String(locationId);
        if (trainerId) newParams.trainerId = String(trainerId);
        return Object.keys(newParams).length > 0 ? newParams : undefined;
    }, [userId, locationId, trainerId]);

    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: sessions,
    } = usePaginatedData("/api/sessions", {
        id: "sessions",
        params,
        initialRowsPerPage: initialRowsPerPage ?? 50,
        mapFn: sessionFromJson,
    });

    const handleSnackBarClose = (
        event: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackBarOpen(false);
    };

    return (
        <>
            <Snackbar
                autoHideDuration={2000}
                open={snackBarOpen}
                onClose={handleSnackBarClose}
                message="email copied"
                sx={{
                    maxWidth: "200px",
                }}
            />
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Sessions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sessions.map((session) => {
                            const sessionUser = session.sessionUsers.find(
                                (su) => su.createdBy === session.createdBy,
                            );
                            const startDateTime = terseTimeFormat(
                                session.startTime,
                            );
                            const endDateime = session.endTime
                                ? terseTimeFormat(session.endTime)
                                : null;
                            const sessionTrainer = session.sessionTrainers.find(
                                (st) => st.sessionId === session.id,
                            );
                            return (
                                <TableRow key={session.id}>
                                    <TableCell sx={{ p: 1.5 }}>
                                        <Stack>
                                            <Typography variant="h5">
                                                {sessionUser?.user.username ??
                                                    ""}
                                            </Typography>
                                            <Typography variant="body2">
                                                {`${sessionUser?.user.firstName} ${sessionUser?.user.lastName}`}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="grey"
                                                alignContent="center"
                                                display="flex"
                                            >
                                                {sessionUser?.user.email ?? ""}
                                                <ContentCopyIcon
                                                    fontSize="small"
                                                    sx={{ p: 0.2 }}
                                                    onClick={async () => {
                                                        await navigator.clipboard.writeText(
                                                            sessionUser?.user
                                                                .email ?? "",
                                                        );
                                                        setSnackBarOpen(true);
                                                    }}
                                                />
                                            </Typography>
                                        </Stack>
                                        <Typography
                                            variant="body2"
                                            sx={{ mt: 2, mb: 0.5 }}
                                        >
                                            {`Trainer ${sessionTrainer?.trainerId ?? "n/a"}`}
                                        </Typography>
                                        <Grid
                                            container
                                            sx={{
                                                display: "flex",
                                                justifyContent: "left",
                                            }}
                                        >
                                            <Grid size={2}>start</Grid>
                                            <Grid size={4}>
                                                {startDateTime.substring(0, 10)}
                                            </Grid>
                                            <Grid size={6}>
                                                {startDateTime.substring(10)}
                                            </Grid>
                                            <Grid size={2}>end</Grid>
                                            <Grid size={4}>
                                                {endDateime
                                                    ? endDateime.substring(
                                                          0,
                                                          10,
                                                      )
                                                    : "-"}
                                            </Grid>
                                            <Grid size={6}>
                                                {endDateime
                                                    ? endDateime.substring(10)
                                                    : "-"}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={6}
                                labelRowsPerPage=""
                                rowsPerPage={rowsPerPage}
                                count={count}
                                page={page}
                                onPageChange={onPageChange}
                                onRowsPerPageChange={onRowsPerPageChange}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    ".MuiInputBase-root": {
                                        marginRight: 1,
                                        marginLeft: 0,
                                    },
                                    ".MuiButtonBase-root": {
                                        padding: 0.5,
                                    },
                                    ".MuiTablePagination-toolbar": {
                                        padding: 0,
                                    },
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
}
