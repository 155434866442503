import * as React from "react";
import { useNavigate, useSearchParams } from "react-router";

import CircularProgress from "@mui/material/CircularProgress";

import { fetchApi } from "../../util";

export default function ShareAccept(): React.JSX.Element {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    React.useEffect(() => {
        async function acceptInvite() {
            const session = searchParams.get("session");
            if (session === null) {
                throw new Error("Sorry, no session ID was provided");
            }

            const workoutsMaybe = searchParams.get("workouts");
            const workoutIds = workoutsMaybe
                ? workoutsMaybe.split(",").map((w) => Number.parseInt(w, 10))
                : undefined;

            await fetchApi("/api/sessions/accept", "POST", {
                sessionId: Number.parseInt(session, 10),
                workoutIds,
            });
        }

        acceptInvite()
            .then(() => navigate("/", { state: { shareSucceeded: true } }))
            .catch(() => navigate("/", { state: { shareSucceeded: true } }));
    }, [navigate, searchParams]);

    return <CircularProgress />;
}
