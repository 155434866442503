import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { ContentProvider, Tag, TagCategoryWithRelations } from "@volley/data";

import { pluralize } from "../../../util/text";

import AppTypeSelect from "./List/Filter/AppTypeSelect";
import PublisherSelect from "./List/Filter/PublisherSelect";
import TagCategorySelect from "./List/Filter/TagCategorySelect";
import { AppId } from "./apps";

interface TaggingProps {
    open: boolean;
    loading: boolean;
    matchCount: number;
    appIdFilter: AppId | null;
    setAppIdFilter: (appId: AppId | null) => void;
    showLatestPosition: boolean;
    useLatestPosition: boolean;
    providers: ContentProvider[];
    selectedProviders: ContentProvider[];
    tagCategories: TagCategoryWithRelations[];
    selectedTags: Record<number, Tag[]>;
    onCancel: () => void;
    onConfirmFilter: () => void;
    updateProviderCount: (updated: ContentProvider[]) => void;
    updateTagCount: (updated: Record<number, Tag[]>) => void;
    updatePositionCount: (latestPosition: boolean) => void;
}

function getHeading(tagCategory: TagCategoryWithRelations): string {
    return `${tagCategory.label} - (${tagCategory.mode === "SINGLE" ? "Choose One" : "Choose Many"})`;
}

export default function AllFilters({
    appIdFilter,
    open,
    loading,
    matchCount,
    showLatestPosition,
    useLatestPosition,
    providers,
    selectedProviders,
    tagCategories,
    selectedTags,
    onCancel,
    onConfirmFilter,
    setAppIdFilter,
    updatePositionCount,
    updateProviderCount,
    updateTagCount,
}: TaggingProps): React.JSX.Element {
    return (
        <Dialog
            open={open}
            fullScreen
            PaperProps={{
                sx: {
                    backgroundColor: (t) => t.palette.primary.dark,
                },
            }}
        >
            <AppBar
                elevation={0}
                sx={{
                    backgroundColor: (t) => t.palette.primary.dark,
                }}
            >
                <Toolbar>
                    <Typography variant="h6">All Filters</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent
                sx={{
                    paddingTop: 8,
                }}
            >
                <Stack spacing={1}>
                    {showLatestPosition && (
                        <Stack>
                            <Typography color="white">
                                Playable from Latest Position
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useLatestPosition}
                                        onChange={(e) => {
                                            updatePositionCount(
                                                e.target.checked,
                                            );
                                        }}
                                        sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                                color: "white",
                                            },
                                        }}
                                    />
                                }
                                label="Last Position"
                                checked={useLatestPosition}
                                sx={{
                                    color: "white",
                                }}
                            />
                        </Stack>
                    )}
                    <Typography color="white">Workout Type</Typography>
                    <AppTypeSelect
                        selectedAppId={appIdFilter}
                        updateSelected={setAppIdFilter}
                    />
                    {tagCategories
                        .filter((tc) => !tc.adminOnly)
                        .map((tagCategory) => (
                            <>
                                <Typography key={tagCategory.id} color="white">
                                    {getHeading(tagCategory)}
                                </Typography>
                                <TagCategorySelect
                                    key={tagCategory.id}
                                    tagCategory={tagCategory}
                                    selectedTags={selectedTags}
                                    updateSelected={(updated) => {
                                        updateTagCount(updated);
                                    }}
                                />
                            </>
                        ))}
                    <Typography color="white">
                        Publisher - (Choose Many)
                    </Typography>
                    <PublisherSelect
                        selectedProviders={selectedProviders}
                        providers={providers}
                        updateSelected={(updated) => {
                            updateProviderCount(updated);
                        }}
                    />
                    <Box component="div" sx={{ flexGrow: 1 }} />
                    <Stack spacing={1} pb={3}>
                        {matchCount > 0 && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => onConfirmFilter()}
                                disabled={matchCount === 0 || loading}
                                fullWidth
                                sx={{
                                    mt: 2,
                                    borderRadius: "16px",
                                }}
                            >
                                {`Show ${matchCount} ${pluralize(matchCount, "Result")}`}
                            </Button>
                        )}
                        {matchCount === 0 && (
                            <Typography
                                variant="button"
                                color="white"
                                textAlign="center"
                            >
                                No Matches Found
                            </Typography>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onCancel();
                            }}
                            fullWidth
                            sx={{
                                mt: 2,
                                borderRadius: "16px",
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
