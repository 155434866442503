import * as React from "react";
import { useParams } from "react-router";

import PluginError from "./PluginError";
import { getApp, getAppId } from "./apps";

export default function PlayPlugin(): React.JSX.Element {
    const { appId: appIdMaybe } = useParams<{ appId: string }>();

    const appId = getAppId(appIdMaybe);

    if (!appId) {
        return <PluginError />;
    }

    const app = getApp(appId);

    return <app.PlayComponent />;
}
