import * as React from "react";
import { useSearchParams } from "react-router";

import BugReportIcon from "@mui/icons-material/BugReport";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import TuneIcon from "@mui/icons-material/Tune";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { sim, convert, model } from "@volley/physics";
import { CourtPoint } from "@volley/physics/dist/models";

import { radToDeg } from "../../../../../util/positionUtil";
import ResizableWorkoutVisualizer from "../../../../common/Visualizer/ResizableWorkoutVisualizer";
import {
    CameraView,
    VisualizerShot,
    VisualizerTrainer,
    WorkoutForVisualizer,
} from "../../../../common/Visualizer/types";
import { Sport, useSelectedSport } from "../../../../common/context/sport";
import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import useRanges from "../../../../hooks/ranges";
import { useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import useThrowNow from "../../../util/useThrowNow";
import Aim from "../../Shared/Aim";
import PlayAppBar from "../../Shared/PlayAppBar";
import Spin from "../../Shared/Spin";

import PointClickAdjust from "./PointClickAdjust";
import PointClickLocalize from "./PointClickLocalize";
import { PositioningState } from "./types";

type TabValue = "position" | "arc" | "spin" | "adjust" | "debug";

const DEFAULT_HINTS: Record<Sport, CourtPoint> = {
    PLATFORM_TENNIS: {
        x: 0,
        y: 0,
        z: 4.25,
    },
    PADEL: {
        x: 0,
        y: 0,
        z: 5.25,
    },
    TENNIS: {
        x: 0,
        y: 0,
        z: 6.25,
    },
    PICKLEBALL: {
        x: 0,
        y: 0,
        z: 4.25,
    },
};

function planShot(
    sim: sim.TrainerSim,
    shot: model.PlannedShot,
): VisualizerShot {
    const target = sim.Target(shot);
    const { launchParams, isValid, invalidMessage } = target;
    if (isValid && launchParams.rpms) {
        const speedSpin = convert.rpm2speedspin(launchParams.rpms);
        return {
            pan: launchParams.yaw,
            tilt: launchParams.pitch,
            spinDirection: speedSpin.spinAxis,
            spinLevel: speedSpin.spinLevel ?? undefined,
            launchSpeed: speedSpin.speed,
        };
    }

    if (invalidMessage.includes("in front of trainer")) {
        throw new Error(
            "Please select a landing point in front of the trainer",
        );
    }
    throw new Error(invalidMessage);
}

const PointClick: React.FC = () => {
    const [searchParams] = useSearchParams();
    const { selected: sport } = useSelectedSport();
    const { physicsModelName } = usePhysicsModelContext();
    const { safeHeight } = useLift();
    const { lift } = useRanges();
    const {
        position: localizedPosition,
        updatePosition,
        localizationStatus,
        error: localizationError,
        cancel: cancelLocalization,
    } = usePosition();

    const [targetPoint, setTargetPoint] = React.useState<CourtPoint | null>(
        null,
    );
    const [tab, setTab] = React.useState<TabValue>("position");
    const [arc, setArc] = React.useState(0);
    const [height, setHeight] = React.useState(safeHeight);
    const [spinDirection, setSpinDirection] = React.useState(0);
    const [spinLevel, setSpinLevel] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [debugTxt, setDebugTxt] = React.useState("");
    const [positioningState, setPositioningState] =
        React.useState<PositioningState>("coordinates");
    const [hintPosition, setHintPosition] = React.useState<CourtPoint>(
        DEFAULT_HINTS[sport],
    );
    const [overrideLocalization, setOverrideLocalization] =
        React.useState(true);
    const [manualYaw, setManualYaw] = React.useState<number>(0);
    const [depthAdjust, setDepthAdjust] = React.useState(0);
    const [lateralAdjust, setLateralAdjust] = React.useState(0);
    const [arcAdjust, setArcAdjust] = React.useState(0);

    const onLocalizeClick = React.useCallback(() => {
        if (!hintPosition) return;

        const oneShot = true;
        const converted = convert.physics2localization({
            x: hintPosition.x,
            y: -hintPosition.z,
            z: 0,
        });
        updatePosition(
            {
                x: converted.x,
                y: converted.y,
                yaw: manualYaw,
                heightIn: height,
            },
            oneShot,
        );

        setPositioningState("confirming");
    }, [hintPosition, updatePosition, manualYaw, height]);

    const onHintPositionConfirm = React.useCallback(() => {
        setPositioningState("confirmed");
        setTab("arc");
        setOverrideLocalization(true);
    }, []);

    const onCancelLocalization = React.useCallback(() => {
        cancelLocalization();
        setPositioningState("height");
    }, [cancelLocalization]);

    const hasLocalizedPosition = !!localizedPosition;
    React.useEffect(() => {
        if (hasLocalizedPosition) {
            setPositioningState("confirmed");
            setTab("arc");
            setOverrideLocalization(false);
        }
    }, [localizationStatus, hasLocalizedPosition]);

    const onVisualizerCoordClick = React.useCallback(
        (coord: CourtPoint | null) => {
            if (
                coord &&
                tab === "position" &&
                (positioningState === "coordinates" ||
                    positioningState === "confirmed")
            ) {
                setOverrideLocalization(true);
                setHintPosition(coord);
                setDebugTxt(JSON.stringify(coord, undefined, 2));
            } else {
                setTargetPoint(coord);
                setLateralAdjust(0);
                setDepthAdjust(0);
                setArcAdjust(0);
                setDebugTxt(JSON.stringify(coord, undefined, 2));
            }
            setErrorMessage("");
        },
        [tab, positioningState],
    );

    const position = React.useMemo<VisualizerTrainer>(() => {
        if (!overrideLocalization && localizedPosition) {
            if (sport === "PLATFORM_TENNIS") {
                return {
                    ...localizedPosition,
                    heightIn: height,
                };
            }

            const converted = convert.localization2physics(localizedPosition);
            return {
                ...converted,
                yaw: localizedPosition.yaw,
                heightIn: height,
            };
        }

        let converted = { ...hintPosition };
        if (sport === "PLATFORM_TENNIS") {
            converted = convert.physics2localization({
                x: hintPosition.x,
                y: -hintPosition.z,
                z: 0,
            });
            return {
                x: converted.x,
                y: converted.y,
                yaw: manualYaw,
                heightIn: height,
            };
        }
        return {
            x: converted.x,
            y: -converted.z,
            yaw: manualYaw,
            heightIn: height,
        };
    }, [
        sport,
        overrideLocalization,
        hintPosition,
        manualYaw,
        localizedPosition,
        height,
    ]);

    const simTrainer = React.useMemo(
        () =>
            new sim.TrainerSim(
                physicsModelName as model.PhysicsModelName,
                sport,
            ),
        [physicsModelName, sport],
    );

    React.useEffect(() => {
        const simPosition: model.TrainerPosition = {
            x: position.x,
            y: position.y,
            yaw: radToDeg(position.yaw),
            h: convert.launchHeightInches2HeadHeight(height),
        };
        if (sport === "PLATFORM_TENNIS") {
            const converted = convert.localization2physics({
                x: position.x,
                y: position.y,
                z: 0,
            });
            simPosition.x = converted.x;
            simPosition.y = converted.y;
        }
        simTrainer.SetPositionManual(simPosition);
    }, [position, sport, simTrainer, height]);

    const workout = React.useMemo<WorkoutForVisualizer>(() => {
        let shot: VisualizerShot | null = null;
        let adjustedShot: VisualizerShot | null = null;
        if (targetPoint) {
            try {
                shot = planShot(simTrainer, {
                    target: {
                        x: targetPoint.x,
                        y: -targetPoint.z,
                        z: targetPoint.y,
                    },
                    spin: spinLevel,
                    spinAxis: spinDirection,
                    peakHeight: null,
                    launchAngle: arc,
                });
                setDebugTxt(JSON.stringify(shot, undefined, 2));
                setErrorMessage("");
            } catch (e: unknown) {
                setErrorMessage((e as Error).message);
            }

            if (depthAdjust || lateralAdjust || arcAdjust) {
                try {
                    adjustedShot = planShot(simTrainer, {
                        target: {
                            x: targetPoint.x + lateralAdjust / 1000,
                            y: -targetPoint.z + depthAdjust / 1000,
                            z: targetPoint.y,
                        },
                        spin: spinLevel,
                        spinAxis: spinDirection,
                        peakHeight: null,
                        launchAngle: arc + arcAdjust,
                    });
                } catch (e: unknown) {
                    setErrorMessage((e as Error).message);
                }
            }
        }

        const shots: VisualizerShot[] = [];
        if (shot) shots.push(shot);
        if (adjustedShot) shots.push(adjustedShot);

        return {
            trainer: {
                ...position,
                heightIn: height,
            },
            shots,
            player: [],
        };
    }, [
        position,
        arc,
        targetPoint,
        simTrainer,
        height,
        spinDirection,
        spinLevel,
        depthAdjust,
        lateralAdjust,
        arcAdjust,
    ]);

    const shotToThrow =
        workout.shots.length === 2 ? workout.shots.at(1) : workout?.shots.at(0);

    const { canThrow, throwing, makeShot, maxSpinLevel } = useThrowNow({
        launchSpeed: shotToThrow?.launchSpeed ?? 0,
        pan: shotToThrow?.pan ?? 0,
        tilt: shotToThrow?.tilt ?? 0,
        spinAxis: shotToThrow?.spinDirection ?? 0,
        spinLevel: shotToThrow?.spinLevel ?? 0,
        height: height ?? lift.min,
    });

    const onPlayClicked = React.useCallback(() => {
        makeShot();
        setTab("adjust");
    }, [makeShot]);

    const cameraView = React.useMemo<CameraView>(() => {
        if (
            tab === "position" &&
            (positioningState === "coordinates" ||
                positioningState === "confirmed")
        )
            return "topDown";
        if (tab === "adjust") return "person";
        return "behindTrainer";
    }, [tab, positioningState]);

    return (
        <Stack sx={{ background: "white", minHeight: "85vh" }} spacing={2}>
            <ResizableWorkoutVisualizer
                workout={workout}
                maxHeight={400}
                sport={sport}
                enableCameraControl={false}
                renderCameraControls={false}
                cameraViewProp={cameraView}
                onCoordClick={onVisualizerCoordClick}
                positionProximity="Good"
                overlay={
                    <Alert
                        severity="warning"
                        onClose={() => setErrorMessage("")}
                        sx={{
                            position: "absolute",
                            display: errorMessage ? undefined : "none",
                        }}
                    >
                        {errorMessage}
                    </Alert>
                }
            />
            <Tabs
                variant="fullWidth"
                allowScrollButtonsMobile
                value={tab}
                onChange={(_e: React.SyntheticEvent, v: TabValue) => setTab(v)}
                sx={{
                    "& .MuiTab-root": {
                        fontSize: 10,
                        minHeight: 24,
                        minWidth: 60,
                        p: 0,
                        pb: 1,
                    },
                    minHeight: 24,
                    mb: 2,
                }}
            >
                <Tab
                    label="Position"
                    value="position"
                    icon={<GpsFixedIcon />}
                />
                <Tab label="Arc" value="arc" icon={<SwapVertIcon />} />
                <Tab label="Spin" value="spin" icon={<ThreeSixtyIcon />} />
                <Tab label="Adjust" value="adjust" icon={<TuneIcon />} />
                {searchParams.has("debug") && (
                    <Tab label="Debug" value="debug" icon={<BugReportIcon />} />
                )}
            </Tabs>
            <Stack spacing={1} sx={{ px: 1, pb: 12 }}>
                {tab === "position" && (
                    <Stack spacing={2}>
                        <PointClickLocalize
                            positioningState={positioningState}
                            setPositioningState={setPositioningState}
                            height={height}
                            onChangeHeight={setHeight}
                            localizationError={localizationError}
                            onCancel={onCancelLocalization}
                            onLocalizeClick={onLocalizeClick}
                            localizationStatus={localizationStatus}
                            onHintPositionConfirm={onHintPositionConfirm}
                            manualYaw={manualYaw}
                            onChangeManualYaw={setManualYaw}
                        />
                    </Stack>
                )}
                {tab === "arc" && (
                    <Aim
                        label="Arc"
                        onAimChanged={(arc) => setArc(arc)}
                        selectedAim={arc}
                    />
                )}
                {tab === "spin" && (
                    <Spin
                        selectedSpin={spinDirection}
                        onSpinChanged={setSpinDirection}
                        selectedSpinIntensity={spinLevel ?? 1}
                        onSpinIntensityChanged={setSpinLevel}
                        maxSpinLevel={maxSpinLevel}
                    />
                )}
                {tab === "adjust" && (
                    <PointClickAdjust
                        depth={depthAdjust}
                        lateral={lateralAdjust}
                        arc={arcAdjust}
                        onDepthChange={setDepthAdjust}
                        onLateralChange={setLateralAdjust}
                        onArcChange={setArcAdjust}
                        onReset={() => {
                            setDepthAdjust(0);
                            setLateralAdjust(0);
                            setArcAdjust(0);
                        }}
                    />
                )}
                {tab === "debug" && <pre>{debugTxt}</pre>}
            </Stack>
            <PlayAppBar
                onPlayClicked={onPlayClicked}
                onPauseClicked={() => {}}
                playState={throwing ? "playing" : "stopped"}
                showRecord={false}
                playDisabled={!canThrow || !position || !shotToThrow}
                pauseDisabled
                recordDisabled
                onRecordClicked={() => {}}
                recordingStatus="Idle"
                playSummary=""
            />
        </Stack>
    );
};

export default PointClick;
