import * as React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export default function ClipDateHeader({
    dateString,
}: {
    dateString: string;
}): React.JSX.Element {
    return (
        <Box component="h2">
            <Divider
                textAlign="left"
                sx={{ "&.MuiDivider-root::before": { width: 0 } }}
            >
                <Typography>{dateString}</Typography>
            </Divider>
        </Box>
    );
}
