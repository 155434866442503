import * as React from "react";

import EastIcon from "@mui/icons-material/East";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import NorthIcon from "@mui/icons-material/North";
import RestoreIcon from "@mui/icons-material/Restore";
import SouthIcon from "@mui/icons-material/South";
import WestIcon from "@mui/icons-material/West";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { feetToMm, mmToFeet } from "../../../../../util/positionUtil";

interface Props {
    /** depth in +- mm */
    depth: number;
    /** lateral in +- mm */
    lateral: number;
    /** arc in +- mm */
    arc: number;
    onDepthChange: React.Dispatch<React.SetStateAction<number>>;
    onLateralChange: React.Dispatch<React.SetStateAction<number>>;
    onArcChange: React.Dispatch<React.SetStateAction<number>>;
    onReset: () => void;
}

const MAX_FEET = 6;
const FOOT_MM = feetToMm(1);
const MAX_DEGREES = 16;

const addFoot = (v: number) => v + FOOT_MM;
const subtractFoot = (v: number) => v - FOOT_MM;
const addDegree = (v: number) => v + 1;
const subtractDegree = (v: number) => v - 1;

const PointClickAdjust: React.FC<Props> = ({
    depth,
    lateral,
    arc,
    onDepthChange,
    onLateralChange,
    onArcChange,
    onReset,
}) => {
    const depthFeet = Math.round(mmToFeet(depth));
    const lateralFeet = Math.round(mmToFeet(lateral));

    return (
        <Grid container spacing={1} minHeight={200}>
            <Grid container size={7}>
                <Grid size={12} display="flex" justifyContent="center">
                    <Button
                        variant={depthFeet < 0 ? "contained" : "outlined"}
                        onClick={() => onDepthChange(subtractFoot)}
                        startIcon={<NorthIcon />}
                        endIcon={<NorthIcon />}
                        disabled={
                            depthFeet < 0 && Math.abs(depthFeet) >= MAX_FEET
                        }
                        fullWidth
                        sx={{ height: "3rem" }}
                    >
                        Shallower
                        {depthFeet < 0 && ` (${Math.abs(depthFeet)}')`}
                    </Button>
                </Grid>
                <Grid container size={12}>
                    <Grid
                        size={4}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            variant={lateralFeet > 0 ? "contained" : "outlined"}
                            onClick={() => onLateralChange(addFoot)}
                            color="primary"
                            startIcon={<WestIcon />}
                            disabled={lateralFeet > MAX_FEET}
                            sx={{ height: "3rem" }}
                            fullWidth
                        >
                            {lateralFeet > 0 && `${lateralFeet}'`}
                        </Button>
                    </Grid>
                    <Grid
                        size={4}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <IconButton
                            onClick={onReset}
                            color="primary"
                            sx={{ height: "3rem" }}
                        >
                            <RestoreIcon />
                        </IconButton>
                    </Grid>
                    <Grid
                        size={4}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            variant={lateralFeet < 0 ? "contained" : "outlined"}
                            onClick={() => onLateralChange(subtractFoot)}
                            color="primary"
                            endIcon={<EastIcon />}
                            disabled={
                                lateralFeet < 0 &&
                                Math.abs(lateralFeet) >= MAX_FEET
                            }
                            sx={{ height: "3rem" }}
                            fullWidth
                        >
                            {lateralFeet < 0 && `${Math.abs(lateralFeet)}'`}
                        </Button>
                    </Grid>
                </Grid>
                <Grid size={12} display="flex" justifyContent="center">
                    <Button
                        variant={depthFeet > 0 ? "contained" : "outlined"}
                        onClick={() => onDepthChange(addFoot)}
                        startIcon={<SouthIcon />}
                        endIcon={<SouthIcon />}
                        disabled={depthFeet >= MAX_FEET}
                        sx={{ height: "3rem" }}
                        fullWidth
                    >
                        Deeper
                        {depthFeet > 0 && ` (${depthFeet}')`}
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                size={5}
                alignItems="center"
                justifyContent="center"
            >
                <Stack spacing={1}>
                    <Button
                        variant={arc > 0 ? "contained" : "outlined"}
                        onClick={() => onArcChange(addDegree)}
                        startIcon={<KeyboardDoubleArrowUpIcon />}
                        endIcon={<KeyboardDoubleArrowUpIcon />}
                        disabled={arc >= MAX_DEGREES}
                        sx={{ height: "3rem" }}
                        size="small"
                    >
                        Higher
                        {arc > 0 && ` ${arc}°`}
                    </Button>
                    <Button
                        variant={arc < 0 ? "contained" : "outlined"}
                        onClick={() => onArcChange(subtractDegree)}
                        startIcon={<KeyboardDoubleArrowDownIcon />}
                        endIcon={<KeyboardDoubleArrowDownIcon />}
                        disabled={arc < 0 && Math.abs(arc) >= MAX_DEGREES}
                        sx={{ height: "3rem" }}
                    >
                        Lower
                        {arc < 0 && ` ${arc}°`}
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default PointClickAdjust;
