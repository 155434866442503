import * as React from "react";

import CategoryIcon from "@mui/icons-material/Category";
import DoneIcon from "@mui/icons-material/Done";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import Button from "@mui/material/Button";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { AttributeFilter } from "../../apps/types";

export interface FilterOption {
    value: string;
    label: string;
    icon: React.JSX.Element;
}

interface FilterProps {
    open: boolean;
    activeAttribute: AttributeFilter;
    onConfirm: (updatedAttribute: AttributeFilter) => void;
    onCancel: () => void;
}

export type FilterDrawerProps = DrawerProps & FilterProps;

export default function SortDrawer({
    open,
    activeAttribute,
    onCancel,
    onConfirm,
}: FilterDrawerProps): React.JSX.Element {
    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={onCancel}
            PaperProps={{
                sx: {
                    borderRadius: "16px 16px 0 0",
                    backgroundColor: (t) => t.palette.primary.main,
                },
            }}
        >
            <Stack
                spacing={2}
                sx={{
                    padding: "10px",
                }}
            >
                <Typography
                    variant="h3"
                    color="white"
                    textAlign="center"
                    sx={{
                        textTransform: "none",
                    }}
                >
                    Sort By
                </Typography>
                <Stack spacing={2} pb="30px">
                    <Button
                        variant="outlined"
                        fullWidth
                        sx={{ color: "white", borderColor: "white" }}
                        onClick={() => onConfirm("globalPlayCount")}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <SportsTennisIcon />
                                <Typography
                                    sx={{
                                        textTransform: "none",
                                    }}
                                >
                                    Total Plays
                                </Typography>
                            </Stack>
                            {activeAttribute === "globalPlayCount" ? (
                                <DoneIcon color="secondary" />
                            ) : undefined}
                        </Stack>
                    </Button>
                    <Button
                        variant="outlined"
                        fullWidth
                        sx={{ color: "white", borderColor: "white" }}
                        onClick={() => onConfirm("userPlayCount")}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <SportsTennisIcon />
                                <Typography
                                    sx={{
                                        textTransform: "none",
                                    }}
                                >
                                    My Plays
                                </Typography>
                            </Stack>
                            {activeAttribute === "userPlayCount" ? (
                                <DoneIcon color="secondary" />
                            ) : undefined}
                        </Stack>
                    </Button>
                    <Button
                        variant="outlined"
                        fullWidth
                        sx={{ color: "white", borderColor: "white" }}
                        onClick={() => onConfirm("lastUserPlay")}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <ScheduleIcon />
                                <Typography
                                    sx={{
                                        textTransform: "none",
                                    }}
                                >
                                    Last Played
                                </Typography>
                            </Stack>
                            {activeAttribute === "lastUserPlay" ? (
                                <DoneIcon color="secondary" />
                            ) : undefined}
                        </Stack>
                    </Button>
                    <Button
                        variant="outlined"
                        fullWidth
                        sx={{ color: "white", borderColor: "white" }}
                        onClick={() => onConfirm("appId")}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <CategoryIcon />
                                <Typography
                                    sx={{
                                        textTransform: "none",
                                    }}
                                >
                                    Workout Type
                                </Typography>
                            </Stack>
                            {activeAttribute === "appId" ? (
                                <DoneIcon color="secondary" />
                            ) : undefined}
                        </Stack>
                    </Button>
                </Stack>
            </Stack>
        </Drawer>
    );
}
